import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import Textarea from "./Textarea";
import UploadImg from "./UploadImg";
import TextEditor from "./TextEditor";
import { useState } from "react";
import CardAddOptionList from "./CardAddOptionList";
import { useEffect } from "react";
import Button from "./Button";
import { useContext } from "react";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
import { toast } from "react-toastify";
import app from "../../../firebase/database";
import UploadBanner from "./UploadBanner";

const EditPage = ({ type, home, id, render, setRender }) => {
  const {
    onSubmitAddPartners,
    onSubmitGetByIdPartners,
    onSubmitUpdatePartners,
    partner,
    userPartner,
  } = useContext(DashboardContext);

  /* INPUTS VALUE */
  const [firebaseImg, setFirebaseImg] = useState("");

  const [companyProfile, setCompanyProfile] = useState("");
  const [namePartner, setNamePartner] = useState("");
  const [typePartner, setTypePartner] = useState("");
  const [benefitsPartner, setBenefitsPartner] = useState("");

  const [emailPartner, setEmailPartner] = useState("");
  const [passwordPartner, setPasswordPartner] = useState("");
  const [cnpjPartner, setCnpjPartner] = useState("");

  const [description, setDescription] = useState("");
  const [contract, setContract] = useState("");

  const [representative, setRepresentative] = useState("");
  const [nationality, setNationality] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [addresses, setAddresses] = useState("");

  const [profession, setProfession] = useState("");
  const [rg, setRg] = useState("");

  const [listContractOption, setListContractOption] = useState([
    { id: 1, value: "" },
  ]);

  const [submit, setSubmit] = useState(false);

  /* INPUTS ERROR */
  const [companyProfileError, setCompanyProfileError] = useState("#D7D7D7");
  const [namePartnerError, setNamePartnerError] = useState("#D7D7D7");
  const [typePartnerError, setTypePartnerError] = useState("#D7D7D7");
  const [benefitsPartnerError, setBenefitsPartnerError] = useState("#D7D7D7");

  const [emailPartnerError, setEmailPartnerError] = useState("#D7D7D7");
  const [passwordPartnerError, setPasswordPartnerError] = useState("#D7D7D7");
  const [cnpjPartnerError, setCnpjPartnerError] = useState("#D7D7D7");

  const [representativeError, setRepresentativeError] = useState("#D7D7D7");
  const [nationalityError, setNationalityError] = useState("#D7D7D7");
  const [maritalStatusError, setMaritalStatusError] = useState("#D7D7D7");
  const [addressesError, setAddressesError] = useState("#D7D7D7");

  const [professionError, setProfessionError] = useState("#D7D7D7");
  const [rgError, setRgError] = useState("#D7D7D7");

  const uploadFileFirebase = async (file) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      setCompanyProfile({ image: reader.result });
    };

    return await app
      .storage()
      .ref(`/parterns/${file.name}`)
      .put(file)
      .then(() => {
        app
          .storage()
          .ref(`/parterns/${file.name}`)
          .getDownloadURL()
          .then(async (url) => {
            setFirebaseImg(url !== undefined && url);
            localStorage.removeItem("@TEMP_ICON");
            localStorage.setItem("@TEMP_ICON", url);
            return url;
          });
      });
  };

  /* FUNCTION TO ADD IMAGES IN STATE */
  const getImages = async (file) => {
    setCompanyProfile(file);
    uploadFileFirebase(file);
  };

  const onSubmitCreated = async () => {
    //  const contractValue = contract.blocks?.length !== undefined && contract.blocks[0].key

    setSubmit(true);
    if (!companyProfile) {
      setCompanyProfileError("var(--negative)");
    } else if (companyProfile) {
      setCompanyProfileError("#D7D7D7");
    }

    if (!namePartner) {
      setNamePartnerError("var(--negative)");
    } else if (namePartner) {
      setTypePartnerError("#D7D7D7");
    }

    if (!typePartner) {
      setTypePartnerError("var(--negative)");
    } else if (typePartner) {
      setTypePartnerError("#D7D7D7");
    }

    if (!benefitsPartner) {
      setBenefitsPartnerError("var(--negative)");
    } else if (benefitsPartner) {
      setBenefitsPartnerError("#D7D7D7");
    }

    if (!emailPartner) {
      setEmailPartnerError("var(--negative)");
    } else if (emailPartner) {
      setEmailPartnerError("#D7D7D7");
    }

    if (!passwordPartner) {
      setPasswordPartnerError("var(--negative)");
    } else if (passwordPartner) {
      setPasswordPartnerError("#D7D7D7");
    }

    if (!cnpjPartner) {
      setCnpjPartnerError("var(--negative)");
    } else if (cnpjPartner) {
      setCnpjPartnerError("#D7D7D7");
    }

    if (!representative) {
      setRepresentativeError("var(--negative)");
    } else if (representative) {
      setRepresentativeError("#D7D7D7");
    }
    if (!nationality) {
      setNationalityError("var(--negative)");
    } else if (nationality) {
      setNationalityError("#D7D7D7");
    }
    if (!maritalStatus) {
      setMaritalStatusError("var(--negative)");
    } else if (maritalStatus) {
      setMaritalStatusError("#D7D7D7");
    }
    if (!addresses) {
      setAddressesError("var(--negative)");
    } else if (addresses) {
      setAddressesError("#D7D7D7");
    }

    if (
      !namePartner ||
      !typePartner ||
      !benefitsPartner ||
      !emailPartner ||
      !passwordPartner ||
      !companyProfile
    ) {
      return toast.error("Veja se todos os campos estão preenchidos", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    //await uploadFileFirebase(companyProfile)

    const data = {
      name: namePartner,
      picture: localStorage.getItem("@TEMP_ICON"),
      benefits: benefitsPartner,
      description: description,
      email: emailPartner,
      password: passwordPartner,
      type: typePartner,
      cnpj: cnpjPartner,
      representative,
      nationality,
      marital_status: maritalStatus,
      addresses,
      rg,
      profession,
      form: listContractOption.map((item) => {
        return { name: item.value };
      }),
      contract: {
        description: JSON.stringify(contract),
      },
    };

    const response = await onSubmitAddPartners(data);

    if (response !== undefined) {
      toast.success("Parceiro adicionado com sucesso!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        home();
      }, 1000);
    }
  };

  const onSubmitUpdate = async () => {
    if (localStorage.getItem("@TEMP_ICON")) {
    }
    let dataPartner = {
      name: namePartner,
      benefits: benefitsPartner,
      description: description,
      type: typePartner,
      email: emailPartner,
      cnpj: cnpjPartner,
      representative,
      nationality,
      marital_status: maritalStatus,
      addresses,
      rg,
      profession,
      contract: {
        description: JSON.stringify(contract),
      },
    };

    console.log(dataPartner, maritalStatus);

    if (localStorage.getItem("@TEMP_ICON")) {
      dataPartner.picture = localStorage.getItem("@TEMP_ICON");
      localStorage.removeItem("@TEMP_ICON");
    }

    if (passwordPartner) {
      dataPartner.password = passwordPartner;
    }

    const dataContract = {
      description: JSON.stringify(contract),
    };

    const dataForm = listContractOption.map((item) => {
      return { name: item.value };
    });

    const response = await onSubmitUpdatePartners(
      dataPartner,
      dataContract,
      dataForm,
      id
    );

    if (response !== undefined) {
      toast.success("Parceiro atualizado com sucesso!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setTimeout(() => {
        home();
      }, 1000);
    }
  };

  const addFieldToList = () => {
    const num = listContractOption[listContractOption.length - 1].id + 1;

    setListContractOption([...listContractOption, { id: num, value: "" }]);
  };

  const removeFieldToList = (item) => {
    if (item !== 1) {
      const removeItem = listContractOption.filter((num) => num !== item);
      setListContractOption(removeItem);
    }
  };

  const onChangeValueForm = (value, id) => {
    listContractOption.map((item) => {
      if (item.id == id) {
        item.value = value;
      }
    });
  };

  const getDetailsPartner = async () => {
    const response = await onSubmitGetByIdPartners(id);

    if (response) {
      setNamePartner(response.partner?.name);
      setTypePartner(response.partner?.type);
      setBenefitsPartner(response.partner?.benefits);

      setEmailPartner(response.user.email);
      setDescription(response.partner?.description);
      setCnpjPartner(response.user?.cnpj);
      setRepresentative(response.user?.representative);
      setNationality(response.user?.nationality);
      setAddresses(response.user?.addresses);
      setMaritalStatus(response.user?.marital_status);
      setRg(response.user?.rg);
      setProfession(response.user?.profession);
      if (response?.contract) {
        setContract(JSON.parse(response?.contract[0]?.description));
      }
      console.log(response.form);
      console.log(listContractOption);

      let dataForm = listContractOption;

      response?.form?.map((item, index) => {
        dataForm[index] = { id: index + 1, value: item.name };
      });
      setListContractOption(dataForm);
    }
  };

  const addModelContract = () => {
    const model = {
      blocks: [
        {
          key: "fqejs",
          text: "CONTRATO DE PRESTAÇÃO DE SERVIÇOS",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 33, style: "fontsize-11pt" },
            { offset: 0, length: 33, style: "BOLD" },
          ],
          entityRanges: [],
          data: { "text-align": "center" },
        },
        {
          key: "6rieu",
          text: "CONTRATANTE: SEUS DADOS – {nome}, pessoa jurídica de direito privado, inscrito no CNPJ sob o nº {cnpj-contratante}, com endereço à {address-contratante}, neste ato representada pelo(a) seu sócio {name-representative-contratante}, {nationality-representative-contratante}, {marital-representative-contratante}, {profession-representative-contratante}, inscrito no CPF sob o nº {cpf-contratante} , portador do RG nº {rg-representative-contratante}, com endereço {address-representative-contratante}.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 263, style: "fontsize-11pt" },
            { offset: 281, length: 36, style: "fontsize-11pt" },
            { offset: 334, length: 1, style: "fontsize-11pt" },
            { offset: 0, length: 11, style: "BOLD" },
            { offset: 13, length: 10, style: "BOLD" },
            { offset: 263, length: 17, style: "color-rgb(33,37,41)" },
            { offset: 317, length: 16, style: "color-rgb(33,37,41)" },
            { offset: 263, length: 17, style: "bgcolor-rgb(255,255,255)" },
            { offset: 317, length: 16, style: "bgcolor-rgb(255,255,255)" },
            { offset: 263, length: 17, style: "fontsize-16" },
            { offset: 317, length: 16, style: "fontsize-16" },
            {
              offset: 263,
              length: 17,
              style: "fontfamily-Poppins, sans-serif",
            },
            {
              offset: 317,
              length: 16,
              style: "fontfamily-Poppins, sans-serif",
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "cqrd1",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "fja2m",
          text: "CONTRATADO: DADOS DE QUEM VAI PRESTAR OS SERVIÇOS – {name-representative-contratada}, pessoa jurídica de direito privado, inscrito no CNPJ sob o nº {cnpj-contratada} , com endereço à {address-contratada}, neste ato representada pelo(a) seu sócio {name-representative-contratada}, {nationality-representative-contratada}, {marital-representative-contratada}, {profession-representative-contratada}, inscrito no CPF sob o nº {cpf-contratada}, portador do RG nº {rg-representative-contratada}, com endereço à {address-contratada}.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 120, style: "fontsize-11pt" },
            { offset: 138, length: 181, style: "fontsize-11pt" },
            { offset: 0, length: 10, style: "BOLD" },
            { offset: 12, length: 37, style: "BOLD" },
            { offset: 120, length: 17, style: "color-rgb(33,37,41)" },
            { offset: 120, length: 17, style: "bgcolor-rgb(255,255,255)" },
            { offset: 120, length: 17, style: "fontsize-16" },
            {
              offset: 120,
              length: 17,
              style: "fontfamily-Poppins, sans-serif",
            },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "6o91r",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "e8ake",
          text: "Pelo presente instrumento particular, as partes acima qualificadas, têm justo e acertado na melhor forma de direito este CONTRATO DE PRESTAÇÃO DE SERVIÇOS, que se regerá pelas cláusulas e condições seguintes, que mutuamente aceitam, a saber.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 241, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "dvc4u",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "7t1js",
          text: "1. DO OBJETO",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 12, style: "fontsize-11pt" },
            { offset: 0, length: 12, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "5h0bf",
          text: "1.1 O presente instrumento tem por objeto a prestação dos serviços descritos no ANEXO I, dentro das especificações lá contidas.\n ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 127, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "c1mup",
          text: "2. DAS OBRIGAÇÕES DA CONTRATANTE",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 32, style: "fontsize-11pt" },
            { offset: 0, length: 32, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "8iqm8",
          text: "Durante toda a vigência do presente contrato, deverá o CONTRATANTE:",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 67, style: "fontsize-11pt" },
            { offset: 55, length: 11, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "710nj",
          text: "2.1 Efetuar os pagamentos pontualmente nas datas estabelecidas neste instrumento, sob pena de pagamento da multa estipulada na CLÁUSULA QUARTA do presente instrumento.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 167, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "1d2v6",
          text: "\n ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "ee2a7",
          text: "2.2 Fornecer ao CONTRATADO\ntodas as informações necessárias para o fiel cumprimento deste instrumento, bem como estar à disposição do CONTRATADO, dentro do horário da prestação do serviço, para esclarecimento de questões que sejam essenciais ao fiel cumprimento do presente instrumento.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 286, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "anse4",
          text: " ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "7scvo",
          text: "3. DAS OBRIGAÇÕES DO CONTRATADO",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 31, style: "fontsize-11pt" },
            { offset: 0, length: 31, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "33fkr",
          text: "Durante a vigência do presente instrumento, deverá o CONTRATADO:",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 64, style: "fontsize-11pt" },
            { offset: 53, length: 10, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "18arj",
          text: "3.1 Utilizar de todos os meios necessários e disponíveis para o fiel cumprimento das cláusulas estabelecidas neste instrumento particular.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 138, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "1s4c",
          text: "3.2 Desenvolver as atividades de acordo com o plano de trabalho e\ncronograma definidos no ANEXO II.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 99, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "950v2",
          text: "3.3 Não divulgar qualquer dado ou informação pessoal ou profissional do CONTRATANTE que tenha sido obtida por meio do cumprimento deste contrato.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 145, style: "fontsize-11pt" },
            { offset: 72, length: 12, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "6t6br",
          text: "3.4 Disponibilizar, quando solicitado, ao CONTRATANTE,\nrelatório das atividades desenvolvidas e dos resultados alcançados.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 122, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "6d3e0",
          text: "3.5 Atuar dentro dos limites, regras e princípios atribuídos à sua atividade pelo respectivo órgão regulamentador, quando houver.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 129, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "2fvt4",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "5hijc",
          text: "4. DA REMUNERAÇÃO",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 17, style: "fontsize-11pt" },
            { offset: 0, length: 17, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "4mqap",
          text: "4.1 Pelos serviços prestados, o CONTRATADO receberá\nremuneração na forma e condições estabelecidas no ANEXO III.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 112, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "41s6",
          text: "4.2 O pagamento será feito por meio de depósito/transferência bancária para conta de titularidade do CONTRATADO, constantes do ANEXO III.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 137, style: "fontsize-11pt" },
            { offset: 101, length: 10, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "b2k45",
          text: "\n ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "4mtrb",
          text: "4.3 O não pagamento na forma e condições estabelecidas no ANEXO III gerará multa no valor de 10% (dez por ceno) do valor da parcela, acrescido de juros e correção monetária.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 173, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "dkmki",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "40hit",
          text: "5. DA VIGÊNCIA E CARGA HORÁRIA",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 30, style: "fontsize-11pt" },
            { offset: 0, length: 30, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "fspet",
          text: "5.1 O presente contrato terá vigência de pelo prazo constante no ANEXO II, contados da data de sua assinatura, podendo ser automaticamente renovado por igual período bastando, para a sua renovação automática que as partes não manifestem expressamente a sua intenção de não dar continuidade ao mesmo. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 300, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "7k40s",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "b2ptt",
          text: "5.2 Durante a vigência deste instrumento, o CONTRATADO deverá prestar os serviços nos horários estabelecidos no ANEXO II.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 121, style: "fontsize-11pt" },
            { offset: 44, length: 11, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "7nukc",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "dso09",
          text: "6. DA RESCISÃO",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 14, style: "fontsize-11pt" },
            { offset: 0, length: 14, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "eqclh",
          text: "6.1 Durante a vigência do presente instrumento, ambas as partes, a qualquer tempo, poderão rescindir o mesmo, devendo, para tal, comunicar por escrito à outra parte a sua intenção, com antecedência mínima de 30 (trinta) dias, período no qual os serviços continuarão a ser prestados normalmente e a remuneração será devida. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 323, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "fbejf",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "fon7b",
          text: "6.2 Pelo descumprimento de qualquer das cláusulas deste instrumento, a parte que der causa pagará à outra parte multa no percentual de 10% (dez por cento) do valor total do contrato, acrescida de honorários advocatícios também no valor de 10% (dez por sendo), sendo facultada a rescisão automática do contrato, sem prejuízo de eventuais perdas e danos. \n ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 353, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "6tera",
          text: "7. DO SIGILO E TRATAMENTO DE DADOS",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 34, style: "fontsize-11pt" },
            { offset: 0, length: 34, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "5mt5e",
          text: "7.1 O CONTRATADO se obriga a manter o mais absoluto sigilo com relação a toda e qualquer informação a que tiver acesso em decorrência deste contrato ou quaisquer informações estratégicas da CONTRATANTE. Para tanto, declara e se compromete a:",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 241, style: "fontsize-11pt" },
            { offset: 5, length: 12, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "2lbrk",
          text: "\n ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "eveej",
          text: "\ta) Manter sigilo, tanto escrito como verbal, ou, por qualquer outra forma, de todos os dados, informações científicas e técnicas e, sobre todos os materiais obtidos com sua participação, podendo incluir, mas não se limitando a: informações técnicas, desenhos, cópias, diagramas, modelos, fluxogramas, croquis, fotografias,\nprogramas de computador, processos, projetos, informações\nestratégicas, métricas de vendas, dentre outros;",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 430, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "c3gee",
          text: "\n ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "f2t87",
          text: "\tb) Não revelar, reproduzir, utilizar ou dar conhecimento, em hipótese alguma, a terceiros, de dados ou quaisquer informações, sem a prévia análise e aprovação do CONTRATANTE.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 175, style: "fontsize-11pt" },
            { offset: 163, length: 11, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "6pjsr",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "4neqe",
          text: "7.2 Caso uma das partes torne-se legalmente obrigada a revelar qualquer informação considerada sigilosa, deverá imediatamente notificar a outra parte sobre tal obrigação, limitando-se a revelar apenas e tão somente as informações que lhes forem requisitadas.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 258, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "b70rl",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "38b23",
          text: "7.3 Caso o CONTRATADO tenha acesso a dados de terceiros durante a vigência deste instrumento, deverá manter sigilo sobre esses dados, bem como realizar seu tratamento de acordo com as normas contidas na Lei Geral de Proteção de Dados – LGPD.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 241, style: "fontsize-11pt" },
            { offset: 11, length: 11, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "6p7i3",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "8s3i6",
          text: "8. DA INEXISTÊNCIA DE VÍNCULO EMPREGATÍCIO.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 43, style: "fontsize-11pt" },
            { offset: 0, length: 43, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "fd5n1",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "q5mg",
          text: "8.1 Para a execução dos serviços objeto deste contrato, o CONTRATADO declara ser responsável por todas as pessoas que contratar, inclusive em relação às obrigações trabalhistas, sendo dever desta pagar e contribuir pontualmente com todos os encargos trabalhistas, previdenciários, tributários e sociais decorrentes de sua atividade e colaboradores. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 349, style: "fontsize-11pt" },
            { offset: 58, length: 11, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "qq7",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "av7eh",
          text: "8.2 O presente contrato não gera vínculo de trabalho/emprego entre os colaboradores e/ou diretores do CONTRATADO, sendo obrigação do CONTRATADO a devolução de todos os gastos e despesas que a CONTRATANTE tiver em razão de ações trabalhistas ajuizadas por colaboradores ou terceiros vinculados à CONTRATADA.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 306, style: "fontsize-11pt" },
            { offset: 102, length: 10, style: "BOLD" },
            { offset: 133, length: 11, style: "BOLD" },
            { offset: 295, length: 10, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "7sd1r",
          text: "\n ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "c8udk",
          text: "8.3. O CONTRATADO, obriga-se, ainda, caso a CONTRATANTE\nseja demandada judicialmente por algum dos colaboradores do CONTRATADO em reembolsar à CONTRATANTE de todas as despesas que tiver em juízo e ou com honorários advocatícios incidentes, comprometendo-se, ainda, a assumir o polo passivo da demanda, sob pena de rescisão contratual. ",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 335, style: "fontsize-11pt" },
            { offset: 7, length: 10, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "543hg",
          text: "\n9. DAS DISPOSIÇÕES GERAIS",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 26, style: "fontsize-11pt" },
            { offset: 1, length: 25, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "1d7ti",
          text: "9.1 As partes declaram total ausência de conflito de interesse na celebração do presente contrato.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 98, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "f2okn",
          text: "9.2 Havendo qualquer dúvida quanto à execução do presente contrato que não esteja expressamente estipulada, as partes ficarão livres para acordarem o que entenderem melhor para o seu total cumprimento.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 201, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "b05du",
          text: "9.3 A tolerância, por qualquer das partes, com relação ao\ndescumprimento de qualquer cláusula ou condição aqui acordados, não poderá ser considerado como desistência na exigência do cumprimento da referida disposição, nem representará novação com relação à obrigação passada, presente ou futura, no tocante à cláusula ou condição cujo descumprimento foi tolerado.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 363, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "80b69",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "df7s",
          text: "10. DO FORO",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 11, style: "fontsize-11pt" },
            { offset: 0, length: 11, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "citqc",
          text: "10.1 As partes elegem o foro da Comarca de São Paulo, no estado de São Paulo, para dirimir qualquer dúvida relativa ao presente instrumento, renunciando expressamente a todos os outros, por mais privilegiados que sejam.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 219, style: "fontsize-11pt" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "a7g0d",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "llau",
          text: "11. DA ASSINATURA",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 17, style: "fontsize-11pt" },
            { offset: 0, length: 17, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "a6bed",
          text: "O presente contrato é assinado eletronicamente, tanto pelas partes quanto por duas testemunhas, através da ferramenta online www.autentique.com.br, que registra as informações de acesso e firma a autenticidade das\nassinaturas abaixo através de IP e do acesso por e-mail das partes envolvidas.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 292, style: "fontsize-11pt" },
          ],
          entityRanges: [{ offset: 125, length: 21, key: 0 }],
          data: {},
        },
        {
          key: "1thv3",
          text: "Por estarem de pleno acordo, assinam o presente CONTRATO DE PRESTAÇÃO DE SERVIÇOS as partes envolvidas e duas testemunhas, para que o mesmo surta os seus efeitos legais.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 169, style: "fontsize-11pt" },
            { offset: 48, length: 34, style: "BOLD" },
          ],
          entityRanges: [],
          data: {},
        },
        {
          key: "e91pr",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "d6dmg",
          text: "Testemunhas",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [
            { offset: 0, length: 11, style: "fontsize-11pt" },
            { offset: 0, length: 11, style: "BOLD" },
          ],
          entityRanges: [],
          data: { "text-align": "center" },
        },
        {
          key: "f66re",
          text: "PARTES: Confirmo, via assinatura eletrônica, nos moldes do art. 10 da MP 2.200/01 em vigor no Brasil, que estou De Acordo com o presente CONTRATO, e, por estar plenamente ciente dos termos, reafirmo meu dever de observar e fazer cumprir as cláusulas aqui estabelecidas, em vista do que posso acessar minha via do contrato através do endereço autentique.com.br e gerar versão impressa do mesmo, considerado o fato de já tê-lo recebido por e-mail.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: { "text-align": "justify" },
        },
        {
          key: "a4a61",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: { "margin-left": "0.18cm" },
        },
      ],
      entityMap: {
        0: {
          type: "LINK",
          mutability: "MUTABLE",
          data: {
            url: "http://www.autentique.com.br/",
            title:
              '<font color="#000000"><font face="Century Gothic, serif"><span lang="pt-BR">www.autentique.com.br</span></font></font>',
            targetOption: "",
            _map: {
              type: "LINK",
              mutability: "MUTABLE",
              data: {
                url: "http://www.autentique.com.br/",
                title:
                  '<font color="#000000"><font face="Century Gothic, serif"><span lang="pt-BR">www.autentique.com.br</span></font></font>',
                targetOption: "",
              },
            },
          },
        },
      },
    };
    setContract(model);
  };

  useEffect(() => {
    /* COMPANY PROFILE */
    if (submit && !companyProfile) {
      setCompanyProfileError("var(--negative)");
    }
    if (submit && companyProfile) {
      setCompanyProfileError("#D7D7D7");
    }

    /* NAME */
    if (submit && !namePartner) {
      setNamePartnerError("var(--negative)");
    }
    if (submit && namePartner) {
      setNamePartnerError("#D7D7D7");
    }

    /* SELECT TYPE */
    if (submit && !typePartner) {
      setTypePartnerError("var(--negative)");
    }
    if (submit && typePartner) {
      setTypePartnerError("#D7D7D7");
    }

    /* BENEFITS */
    if (submit && !benefitsPartner) {
      setBenefitsPartnerError("var(--negative)");
    }
    if (submit && benefitsPartner) {
      setBenefitsPartnerError("#D7D7D7");
    }

    if (submit && !rg) {
      setRgError("var(--negative)");
    }
    if (submit && rg) {
      setRgError("#D7D7D7");
    }

    if (submit && !profession) {
      setProfessionError("var(--negative)");
    }
    if (submit && profession) {
      setProfessionError("#D7D7D7");
    }

    /* EMAIL */
    if (submit && !emailPartner) {
      setEmailPartnerError("var(--negative)");
    }
    if (submit && emailPartner) {
      setEmailPartnerError("#D7D7D7");
    }

    /* PASSWORD */
    if (submit && !passwordPartner) {
      setPasswordPartnerError("var(--negative)");
    } else if (submit && passwordPartner) {
      setPasswordPartnerError("#D7D7D7");
    }

    /* CONFIRM PASSWORD */
    if (submit && !passwordPartner) {
      setPasswordPartnerError("var(--negative)");
    } else if (submit && passwordPartner) {
      setPasswordPartnerError("#D7D7D7");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyProfile,
    namePartner,
    typePartner,
    benefitsPartner,
    emailPartner,
    passwordPartner,
    description,
    contract,
    listContractOption,
    cnpjPartner,
    profession,
    rg,
  ]);

  useEffect(() => {
    if (type == "edit") {
      getDetailsPartner();
    } else {
      setContract(null);
    }
  }, [type]);

  useEffect(() => {}, [maritalStatus]);

  const container = {
    width: "100%",
    marginTop: "41px",
  };

  const containerBox = {
    width: "100%",
    border: "1px solid #D7D7D7",
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "22px",
  };

  const formBox = {
    display: "flex",
    gap: "20px",
    marginTop: "20px",
  };

  const containerBoxBotton = {
    width: "100%",
    height: "790px",
    display: "flex",
    gap: "25px",
    marginTop: "29px",
  };

  const boxAddOptions = {
    width: "50%",
    border: "1px solid #D7D7D7",
    borderRadius: "8px",
    backgroundColor: "#fff",
    padding: "25px 22px",
    overflowY: "auto",
  };

  const boxContract = {
    width: "50%",
    border: "1px solid #D7D7D7",
    borderRadius: "8px",
    backgroundColor: "#fff",
  };

  return (
    <div style={container}>
      <div style={containerBox}>
        <div style={{ width: "324px" }}>
          <h3 className="textTitleEditPage">Informações iniciais</h3>
          <UploadImg
            borderColor={companyProfileError}
            companyProfile={companyProfile}
            uploadFile={getImages}
          />
        </div>

        <div style={formBox}>
          <Input
            borderColor={namePartnerError}
            width="100%"
            onChange={(e) => setNamePartner(e.target.value)}
            value={namePartner}
            name="Nome do parceiro"
            placeholder=""
            type={"text"}
          />
          <Input
            mask={"99.999.999/9999-99"}
            borderColor={cnpjPartnerError}
            width="100%"
            onChange={(e) => setCnpjPartner(e.target.value)}
            value={cnpjPartner}
            name="CNPJ do parceiro"
            placeholder=""
            type={"text"}
          />
          <InputSelectFilterManager
            borderColor={typePartnerError}
            onChange={(e) => setTypePartner(e.target.value)}
            selectOption={typePartner}
            color="var(--grey-1)"
            name="Tipo de parceiro"
            width="100%"
            top="50px"
            custom="false"
            type="business"
          />
          <Input
            mask={"99.999.999-9"}
            borderColor={rgError}
            width="100%"
            onChange={(e) => setRg(e.target.value)}
            value={rg}
            name="RG do Representante"
            placeholder=""
            type={"text"}
          />
        </div>

        <div style={{ ...formBox, marginTop: "0" }}>
          <Input
            borderColor={representativeError}
            width="100%"
            onChange={(e) => setRepresentative(e.target.value)}
            value={representative}
            name="Representante legal"
            placeholder=""
            type={"text"}
          />
          <Input
            borderColor={nationalityError}
            width="100%"
            onChange={(e) => setNationality(e.target.value)}
            value={nationality}
            name="Nacionalidade"
            placeholder=""
            type={"text"}
          />
          <Input
            borderColor={maritalStatusError}
            width="100%"
            onChange={(e) => setMaritalStatus(e.target.value)}
            value={maritalStatus}
            name="Estado civil"
            placeholder=""
            type={"text"}
          />
          <Input
            borderColor={addressesError}
            width="100%"
            onChange={(e) => setAddresses(e.target.value)}
            value={addresses}
            name="Endereços"
            placeholder=""
            type={"text"}
          />
        </div>

        <div style={{ ...formBox, marginTop: "0" }}>
          <Input
            borderColor={professionError}
            onChange={(e) => setProfession(e.target.value)}
            value={profession}
            width="100%"
            name="Profissão"
            placeholder=""
            type={"text"}
          />
          <Input
            borderColor={benefitsPartnerError}
            onChange={(e) => setBenefitsPartner(e.target.value)}
            value={benefitsPartner}
            width="100%"
            name="Benefícios"
            placeholder=""
            type={"text"}
          />
          <Input
            borderColor={emailPartnerError}
            onChange={(e) => setEmailPartner(e.target.value)}
            value={emailPartner}
            width="100%"
            name="E-mail de login"
            placeholder=""
            type={"text"}
          />
          <Input
            borderColor={passwordPartnerError}
            onChange={(e) => setPasswordPartner(e.target.value)}
            value={passwordPartner}
            name="Senha"
            width="100%"
            placeholder="Insira uma senha"
            type={"password"}
          />
        </div>

        <div>
          <Textarea
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            name="Observações adicionais"
            height="110px"
            width="100%"
          />
        </div>
        <UploadBanner />
      </div>

      <div style={containerBoxBotton}>
        <div style={boxAddOptions}>
          <h3 className="textTitleContract">Formulario de cotação</h3>
          <div style={{ marginTop: "10px" }}>
            {listContractOption.map((item) => {
              console.log(item);
              return (
                <CardAddOptionList
                  typeValue="virtual"
                  value={item.value}
                  onChange={(e) => onChangeValueForm(e.target.value, item.id)}
                  key={item}
                  num={item.id}
                  onClick={() => removeFieldToList(item)}
                />
              );
            })}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                cursor: "pointer",
              }}
              onClick={() => addFieldToList()}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10H15"
                  stroke="#102033"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 15V5"
                  stroke="#102033"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <p className="textAddNewRoute">Adicionar novo campo</p>
            </div>
          </div>
        </div>
        <div style={boxContract}>
          <div
            style={{
              padding: "19px 20px 16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 className="textTitleContract">Contrato</h3>
            <div
              onClick={addModelContract}
              className="textTitleContract"
              style={{
                backgroundColor: "var(--color-primary)",
                color: "#fff",
                padding: "5px 10px",
                fontSize: "12px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Adicionar modelo
            </div>
          </div>
          <div>
            <TextEditor value={contract} setContract={setContract} />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "34px  ",
        }}
      >
        {(type == "edit" && (
          <Button
            width={"200px"}
            height="45px"
            background="var(--color-primary)"
            borderRadius="60px"
            marginTop="0"
            name="Editar parceiro"
            selectBtn={"Editar parceiro"}
            border="none"
            onClick={() => {
              setTimeout(() => {
                onSubmitUpdate();
              }, 1000);
            }}
          />
        )) ||
          (type == "added" && (
            <Button
              width={"200px"}
              height="45px"
              background="var(--color-primary)"
              borderRadius="60px"
              marginTop="0"
              name="Criar parceiro"
              selectBtn={"Criar parceiro"}
              border="none"
              onClick={onSubmitCreated}
            />
          ))}
      </div>
    </div>
  );
};

export default EditPage;
