import Input from "./Input"
import trashIcon from "../../../images/svg/trashIcon.svg"
import { useState } from "react"
import { useEffect } from "react"

const CardAddOptionList = ({ num, onClick, notTrash, placeholder, value, onBlur, id, index, typeValue, disabled,onChange }) => {

    const [valueInput, setValueInput] = useState(value)

    useEffect(() => {
        setValueInput(value)
    }, [value])

    const container = {
        display: "flex",
        alignItems: "center",
        gap: "21px"
    }

    const containerBox = {
        border: "1px solid trasnparent",
        backgroundColor: "var(--color-primary)",
        borderRadius: "60px",
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    return (
        <div style={container}>
            <div style={containerBox}>
                <p style={{ fontSize: "21px", color: "#fff" }}>{num}</p>
            </div>

            <div style={{ width: "100%", display: "flex", alignItems: "center", gap: "13px" }}>
                <Input
                    index={index}
                    disabled={disabled}
                    id={id}
                    onChange={(e) => {
                        setValueInput(e.target.value)
                        if(onChange){
                            onChange(e)
                        }
                    }}
                    onBlur={(e) => {
                        if (onBlur) {
                            onBlur(e)
                        }
                    }}
                    width="100%"
                    name={"Nome do campo"}
                    placeholder={placeholder}
                    type={"text"}
                    value={typeValue == "virtual" ? valueInput : value} />
                {!notTrash && num !== 1 && <img onClick={onClick} style={{ cursor: "pointer" }} src={trashIcon} />}
            </div>
        </div>
    )
}

export default CardAddOptionList