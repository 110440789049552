import React, { useState, useEffect } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import FilterUsers from "../components/FilterUsers";
import TableListUser from "../components/TableListUser";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
import { useContext } from "react";

const DashboardAdm = () => {
  const { users, usersFilter, getFilterUsers, getFilterUsersSearch } =
    useContext(DashboardContext);
  const [companies, setCompanies] = useState([]);

  const [testState, setTestState] = useState();

  useEffect(() => {
    if (users.length > 0) {
      const filter = users.map((item) => item.company);
      setCompanies(filter);
    }
  }, [users]);

  useEffect(() => {
    if (testState == "{{nome}}") {
      testState.replace("{{nome}}", ",<b>Test</b>");
    }
  }, [testState]);

  return (
    <ThowColumLayout
      page={"DashboardAdm"}
      colum1={<Sidebar access="adm" page="Dashboard" />}
      colum2={
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "57px",
            }}
          >
            <HeaderDashboard name="Usuários" type="adm" />

            <FilterUsers
              onChangeSearch={(e) => getFilterUsersSearch(e.target.value)}
              placeholder={"Pesquisar usuario"}
              onChangeType={(e) => getFilterUsers(e.target.value)}
              companies={companies}
              type={"company"}
              select
              name="Empresa:"
              page="DashboardAdm"
              sector="Atuação:"
            />
          </div>
          <TableListUser />
        </>
      }
    ></ThowColumLayout>
  );
};

export default DashboardAdm;
