/* eslint-disable no-dupe-keys */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import closeEyeIcon from "../../../images/svg/closeEyeIcon.svg";
import openEyeIcon from "../../../images/svg/openEyeIcon.svg";
import InputMask from "react-input-mask";
const Input = ({
  name,
  type,
  placeholder,
  onChange,
  width,
  error,
  height,
  marginLeft,
  color,
  value,
  rightEye,
  topEye,
  mask,
  maxWidth,
  icon,
  deletIcon,
  setValue,
  id,
  index,
  destroyIcon,
  borderColor,
  onBlur,
  disabled,
  deleteContent,
  label,
}) => {
  const [stateEye, setStateEye] = useState(false);

  const deleteValueInput = () => {
    deleteContent("Destroy");
    setValue("");
  };

  const containerInput = {
    display: "flex",
    flexDirection: "column",
    marginLeft: marginLeft,
    width: width,
    maxWidth: maxWidth,
  };

  const styleInput = {
    width: width != undefined ? width : "418px",
    color: color != undefined ? color : "",
    height: height != undefined ? height : "50px",
    borderRadius: "10px",
    border: `1px solid ${borderColor || "#D7D7D7"}`,
    marginBottom: "1.6875rem",
    outline: "none",
  };

  const styleInputIcon = {
    width: width != undefined ? width : "418px",
    color: color != undefined ? color : "",
    height: height != undefined ? height : "50px",
    borderRadius: "10px",
    border: "1px solid #D7D7D7",
    marginBottom: "1.6875rem",
    outline: "none",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 5px 5px 12px",
  };

  const styleLabel = {
    marginBottom: "14.43px",
  };

  const containerInputMail = {
    width: width != undefined ? width : "418px",

    display: "flex",
    flexDirection: "column",
    position: "relative",
  };

  const containerInputPassowrd = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: width != undefined ? width : "418px",
  };

  const image = {
    position: "absolute",
    right: 0,
    top: topEye != undefined ? topEye : "50px",
    right: rightEye != undefined ? rightEye : "10px",
    cursor: "pointer",
  };

  return (
    <div style={containerInput}>
      {type == "password" ? (
        <div style={containerInputPassowrd}>
          <label className="textPrimaryLabelInput" style={styleLabel}>
            {name}
          </label>
          <input
            className="textPrimaryInput"
            onChange={onChange}
            placeholder={placeholder}
            type={stateEye == true ? "text" : type}
            style={styleInput}
          />

          {stateEye == false ? (
            <img
              src={closeEyeIcon}
              onClick={() => setStateEye(!stateEye)}
              style={image}
            />
          ) : (
            <img
              src={openEyeIcon}
              onClick={() => setStateEye(!stateEye)}
              style={image}
            />
          )}

          {error != undefined ? (
            <p className="textErrorInput">{error}</p>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div style={containerInputMail}>
          <label className="textPrimaryLabelInput" style={styleLabel}>
            {name}
          </label>

          <div style={icon ? styleInputIcon : {}}>
            {(icon && value && (
              <>
                <label htmlFor={label} style={{ position: "relative" }}>
                  <img
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "6px",
                      left: 0,
                      width: "25px",
                    }}
                    src={icon}
                  />
                </label>

                <label style={{ position: "relative" }}>
                  <img
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      top: "5px",
                      left: 0,
                      width: "25px",
                    }}
                    onClick={() => deleteValueInput()}
                    src={destroyIcon}
                  />
                </label>
              </>
            )) ||
              (icon && !value && (
                <>
                  <label htmlFor={label} style={{ position: "relative" }}>
                    <img
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        top: "6px",
                        left: 0,
                        width: "25px",
                      }}
                      src={icon}
                    />
                  </label>
                </>
              ))}
            <InputMask
              disabled={disabled}
              onBlur={onBlur}
              id={id}
              maskChar=""
              mask={mask}
              className="textPrimaryInput"
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              type={type}
              style={
                icon
                  ? {
                      outline: "none",
                      border: "none",
                      padding: 0,
                      width: "65%",
                    }
                  : styleInput
              }
            />
          </div>
          {error != undefined ? (
            <p className="textErrorInput">{error}</p>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default Input;
