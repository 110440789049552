import { useState } from "react";
import uploadIcon from "../../../images/svg/uploadIcon.svg"

const UploadImg = ({borderColor, uploadFile, companyProfile}) => {

    /* INPUT FILE IMAGES */
    const [iconUpload, setIconUpload] = useState(false)

    /* STYLES COMPONENTS */
    const container = {
        marginTop: "20px",
        border: `2px dashed ${borderColor || "#102033"}`,
        borderRadius: "10px",
        width: "100%"
    }

    const boxContent = {
        display: "flex",
        justifyContent: "center",
        padding: "13px",
        width: "100%"
    }

    const boxIcon = {
        display: "flex",
        alignItems: "center",
        gap: '13px',
        cursor: "pointer",
        width: "100%",
        justifyContent: "space-between"
    }

    const boxImgAndIcon = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"

    }

    return (
        <>
            <div style={container}>
                <div style={boxContent}>
                    <div style={boxImgAndIcon}>
                        <label htmlFor="file" style={boxIcon}>
                            <div style={{ display: "flex", gap: "17px" }}>
                                <img src={uploadIcon} />
                                <div>
                                    <p style={{ transform: "translateY(3px)" }} className='textUploadImg'>Upload da logo</p>
                                    <p style={{ transform: "translateY(3px)" }} className='textUploadImgType'>PNG ou SVG</p>
                                </div>
                            </div>

                            <div>
                                {
                                    companyProfile ? (
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="12" cy="12" r="11.5" fill="#062F67" stroke="#062F67" />
                                            <path d="M17.3347 7.99951L10.0013 15.3328L6.66797 11.9995" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
        

                                    ) : (
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.4961 0C5.87909 0 0.496094 5.383 0.496094 12C0.496094 18.617 5.87909 24 12.4961 24C19.1131 24 24.4961 18.617 24.4961 12C24.4961 5.383 19.1131 0 12.4961 0ZM16.4961 13H12.4961C11.9441 13 11.4961 12.553 11.4961 12V6C11.4961 5.447 11.9441 5 12.4961 5C13.0481 5 13.4961 5.447 13.4961 6V11H16.4961C17.0491 11 17.4961 11.447 17.4961 12C17.4961 12.553 17.0491 13 16.4961 13Z" fill="#F4DC01" />
                                        </svg>


                                    )
                                }

                            </div>

                        </label>
                    </div>
                </div>
            </div>
            <input onChange={(e) => uploadFile(e.target.files[0])} accept='image/png' name="file" id="file" type="file" style={{ display: "none" }} />
        </>
    )
}

export default UploadImg