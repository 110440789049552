import { useEffect } from "react"
import Input from "./Input"
import { useState } from "react"


const JourneyNameComponent = ({ onChange, value, id }) => {
    const [nameJourney, setNameJourney] = useState()

    useEffect(() => {
        setNameJourney(value)
    }, [value])

    return (
        <Input value={nameJourney} onChange={(e)=>setNameJourney(e.target.value)} width="341px" id={id} onBlur={onChange} name="Nome da jornada" placeholder="Escreva a pergunta" type={"text"} />
    )
}

export default JourneyNameComponent