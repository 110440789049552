import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
import CardJourneyList from "./CardJourneyList";
import JourneyNameComponent from "./JourneyNameComponent";
import { toast } from "react-toastify";

const FormAddJourney = ({ idJourney, edit }) => {
  const {
    journey,
    setJourney,
    onSubmitDeleteQuestion,
    onSubmitAddJourney,
    onSubmitUpdateJourney,
    onSubmitUpdateQuestion,
    onSubmitAddQuestion,
    onSubmitGetJourney,
  } = useContext(DashboardContext);

  const [newId, setNewId] = useState("");
  const [nameJourney, setNameJourney] = useState("");
  const [removeLast, setRemoveLast] = useState(false);

  useEffect(() => {
    journey.map((item) => {
      if (item.id == idJourney) {
        item.questions.length > 1 ? setRemoveLast(true) : setRemoveLast(false);
      }
    });
  }, []);

  const success = () => {
    toast.success(
      `Jornada ${edit ? "atualizada" : "cadastrada"} com sucesso!`,
      {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    setTimeout(() => {
      window.location.reload();
    }, 1800);
  };

  const addFieldToList = () => {
    let newList = {};

    journey.map((item) => {
      const newQuestion = {
        name: "",
        macro: "",
        column: "",
        order: "",
        positive: {
          task: "",
          partner: "",
          content: "",
          points: "",
          type: "",
        },
        partial: {
          task: "",
          partner: "",
          content: "",
          points: "",
          type: "",
        },
        negative: {
          task: "",
          partner: "",
          content: "",
          points: "",
          type: "",
        },
      };

      if (item.id == idJourney || newId == item.id) {
        newList = item;
        newList.questions.push(newQuestion);
      }
    });

    setJourney([newList]);
  };

  const removeFieldToList = async () => {
    console.log(5555);

    if (idJourney) {
      journey.map(async (item) => {
        if (item.id == idJourney) {
          const lastItem = item.questions[item.questions.length - 1];
          await onSubmitDeleteQuestion(lastItem.id);
        }
      });
    }
  };

  /* CHANGE DO INPUT JOURNEY */
  const onChangeNameJourney = (e, id, index) => {
    let value = e.target.value;
    let newJourney = journey;
    newJourney.map((item) => {
      if (item.id == id) {
        onSubmitUpdateJourney(id, { name: value });
        item.name = value;
      }
    });
    setJourney([...newJourney]);
  };

  /* CHANGE DO INPUT JOURNEY CREATED  */
  const onChangeNameJourneyCreated = async (e, id, index) => {
    let value = e.target.value;
    setNameJourney(value);

    if (!newId) {
      /* CREATED */
      const response = await onSubmitAddJourney({ name: value });
      setJourney([...journey, response.data]);
      return setNewId(response.data.id);
    }

    setNameJourney(value);
    const response = await onSubmitUpdateJourney(id, { name: value });
    return setNewId(response.data.id);
  };

  const boxAddNewRoute = {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    marginTop: "20px",
    cursor: "pointer",
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          border: "1px solid #D7D7D7",
          marginTop: "39px",
          padding: "22px",
          borderRadius: "8px",
          backgroundColor: "#fff",
        }}
      >
        {edit ? (
          journey.map((item, indexJourney) => {
            if (item.id == idJourney) {
              return (
                <>
                  <JourneyNameComponent
                    width="341px"
                    id={item.id}
                    onChange={(e) =>
                      onChangeNameJourney(e, item.id, indexJourney)
                    }
                    value={item.name}
                    name="Nome da jornada"
                    placeholder="Escreva a pergunta"
                    type={"text"}
                  />

                  {item.questions.map((quest, indexQuest) => {
                    console.log(quest);
                    let typeQuestion = "";

                    return (
                      <>
                        <CardJourneyList
                          destroy={removeFieldToList}
                          newId={idJourney}
                          questionId={quest.id}
                          indexQuest={indexQuest}
                          nameQuestion={quest.name}
                          macroQuestion={quest.macro}
                          columnQuestion={quest.column}
                          orderQuestion={quest.order}
                          positiveTaskQuestion={quest?.positive?.task}
                          positivePartnerQuestion={quest?.positive?.partner}
                          positiveContentQuestion={quest?.positive?.content}
                          positivePointsQuestion={quest?.positive?.points}
                          partialTaskQuestion={quest?.partial?.task}
                          partialPartnerQuestion={quest?.partial?.partner}
                          partialContentQuestion={quest?.partial?.content}
                          partialPointsQuestion={quest?.partial?.points}
                          negativeTaskQuestion={quest?.negative?.task}
                          negativePartnerQuestion={quest?.negative?.partner}
                          negativeContentQuestion={quest?.negative?.content}
                          negativePointsQuestion={quest?.negative?.points}
                          positiveId={quest?.positive?.id}
                          partialId={quest?.partial?.id}
                          negativeId={quest?.negative?.id}
                        />
                      </>
                    );
                  })}
                </>
              );
            }
          })
        ) : (
          <>
            {(!newId && (
              <>
                <div>
                  <JourneyNameComponent
                    width="341px"
                    id={newId || "1"}
                    onChange={(e) => onChangeNameJourneyCreated(e)}
                    value={nameJourney}
                    name="Nome da jornada"
                    placeholder="Escreva a pergunta"
                    type={"text"}
                  />
                </div>

                <CardJourneyList
                  destroy={removeFieldToList}
                  notJourney={true}
                  newId={null}
                  questionId={null}
                  indexQuest={0}
                  nameQuestion={null}
                  macroQuestion={null}
                  columnQuestion={null}
                  orderQuestion={null}
                  positiveTaskQuestion={null}
                  positivePartnerQuestion={null}
                  positiveContentQuestion={null}
                  positivePointsQuestion={null}
                  partialTaskQuestion={null}
                  partialPartnerQuestion={null}
                  partialContentQuestion={null}
                  partialPointsQuestion={null}
                  negativeTaskQuestion={null}
                  negativePartnerQuestion={null}
                  negativeContentQuestion={null}
                  negativePointsQuestion={null}
                />
              </>
            )) ||
              (newId &&
                journey.map((item, index) => {
                  if (item.id == newId) {
                    return (
                      <>
                        <div>
                          <JourneyNameComponent
                            width="341px"
                            id={item.id}
                            onChange={(e) =>
                              onChangeNameJourneyCreated(e, item.id)
                            }
                            value={nameJourney}
                            name="Nome da jornada"
                            placeholder="Escreva a pergunta"
                            type={"text"}
                          />
                        </div>

                        {(item.questions.length > 0 &&
                          item.questions.map((quest, indexQuest) => {
                            return (
                              <>
                                <CardJourneyList
                                  destroy={removeFieldToList}
                                  newId={idJourney || newId}
                                  questionId={quest.id}
                                  indexQuest={indexQuest}
                                  nameQuestion={quest.name}
                                  macroQuestion={quest.macro}
                                  columnQuestion={quest.column}
                                  orderQuestion={quest.order}
                                  positiveTaskQuestion={quest?.positive?.task}
                                  positivePartnerQuestion={
                                    quest?.positive?.partner
                                  }
                                  positiveContentQuestion={
                                    quest?.positive?.content
                                  }
                                  positivePointsQuestion={
                                    quest?.positive?.points
                                  }
                                  partialTaskQuestion={quest?.partial?.task}
                                  partialPartnerQuestion={
                                    quest?.partial?.partner
                                  }
                                  partialContentQuestion={
                                    quest?.partial?.content
                                  }
                                  partialPointsQuestion={quest?.partial?.points}
                                  negativeTaskQuestion={quest?.negative?.task}
                                  negativePartnerQuestion={
                                    quest?.negative?.partner
                                  }
                                  negativeContentQuestion={
                                    quest?.negative?.content
                                  }
                                  negativePointsQuestion={
                                    quest?.negative?.points
                                  }
                                  positiveId={quest?.positive?.id}
                                  partialId={quest?.partial?.id}
                                  negativeId={quest?.negative?.id}
                                />
                              </>
                            );
                          })) ||
                          (item.questions.length == 0 && (
                            <>
                              <CardJourneyList
                                destroy={removeFieldToList}
                                notQuestion={true}
                                newId={newId}
                                questionId={null}
                                indexQuest={null}
                                nameQuestion={null}
                                macroQuestion={null}
                                columnQuestion={null}
                                orderQuestion={null}
                                positiveTaskQuestion={null}
                                positivePartnerQuestion={null}
                                positiveContentQuestion={null}
                                positivePointsQuestion={null}
                                partialTaskQuestion={null}
                                partialPartnerQuestion={null}
                                partialContentQuestion={null}
                                partialPointsQuestion={null}
                                negativeTaskQuestion={null}
                                negativePartnerQuestion={null}
                                negativeContentQuestion={null}
                                negativePointsQuestion={null}
                              />
                            </>
                          ))}
                      </>
                    );
                  }
                }))}
          </>
        )}
        <div>
          {
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={boxAddNewRoute} onClick={() => addFieldToList()}>
                <div
                  style={{
                    transform: "translateY(-2px)",
                  }}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 10.7812H15"
                      stroke="#062F67"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 15.7812V5.78125"
                      stroke="#062F67"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="textAddNewRoute">Adicionar nova pergunta</p>
              </div>

              {removeLast && (
                <div style={boxAddNewRoute} onClick={() => removeFieldToList()}>
                  <div
                    style={{
                      transform: "translateY(-2px)",
                    }}
                  >
                    <svg
                      width="12"
                      height="3"
                      viewBox="0 0 12 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.78125H11"
                        stroke="#EA0000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="textRemoveNewRoute">Remover pergunta</p>
                </div>
              )}
            </div>
          }
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "40px",
          marginBottom: "50px",
        }}
      >
        <Button
          width={"200px"}
          height="45px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Salvar jornada"
          selectBtn={"Salvar jornada"}
          border="none"
          onClick={() => success()}
        />
      </div>
    </>
  );
};

export default FormAddJourney;
