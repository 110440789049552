import React, { useContext, useState, useEffect } from "react";
import { ModalContext } from "../../../context/Modal";
import { UserContext } from "../../../context/User";
import api from "../../../services/api";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
const FilterUsers = ({
  onChangeType,
  onChangeSearch,
  select,
  page,
  onClick,
  name,
  width,
  type,
  companies,
  placeholder,
  sector,
  widthAlternative,
}) => {
  const { getByName } = useContext(UserContext);
  const { users, usersFilter, setUsersFilter } = useContext(DashboardContext);

  const { setStateModalGlobal, deleteItems } = useContext(ModalContext);
  const [userName, setUserName] = useState();

  const storage = () => {
    setStateModalGlobal(true);
  };

  const remove = async () => {
    const response = await api.post("all/users");

    if (response.status == 200) {
      window.location.href = "/dashboard/users";
    }
  };

  useEffect(() => {
    //   getByName(userName)
  }, [userName]);

  const inputPrimary = {
    border: "1px solid #D7D7D7",
    width: "250px",
    height: "45px",
    borderRadius: "60px",
    outline: "none",
    paddingLeft: "50px",
  };
  const btnStyle = {
    border: "none",
    outline: "none",
    background: "var(--color-primary)",
    borderRadius: "60px",
    width: "176px",
    height: "45px",
  };
  const btnStyleRemove = {
    border: "none",
    outline: "none",
    background: "#ffe1e1",
    borderRadius: "60px",
    width: "176px",
    height: "45px",
  };
  const selectPrimary = {
    border: "1px solid #D7D7D7",
    width: widthAlternative ? "350px" : "300px",
    height: "45px",
    borderRadius: "60px",
    padding: "12px 19px 13px 16px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    justifyContent: "space-between",
    backgroundColor: "#fff",
  };

  return (
    <div className="containerBox" style={{ marginBottom: "43px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "18px" }}>
          <div style={{ position: "relative" }}>
            <input
              onChange={onChangeSearch}
              className="textInputPrimaryFilter"
              placeholder={placeholder}
              style={inputPrimary}
            />

            <div style={{ position: "absolute", top: "7px", left: "13px" }}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 0C13.968 0 18 4.032 18 9C18 13.968 13.968 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0ZM9 16C12.867 16 16 12.867 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16ZM17.485 16.071L20.314 18.899L18.899 20.314L16.071 17.485L17.485 16.071Z"
                  fill="#123164"
                />
              </svg>
            </div>
          </div>

          {select && (
            <div style={selectPrimary}>
              <p className="textSelectCompanyHeader">{name}</p>
              <select
                name="select"
                onChange={onChangeType}
                style={{
                  outline: "none",
                  border: "none",
                  width: width || "200px",
                }}
              >
                {type == "partner" && (
                  <>
                    <option value="default">Todos</option>
                    <option value="Jurídico">Jurídico</option>
                    <option value="Pessoas">Pessoas</option>
                    <option value="Estratégico">Estratégico</option>
                    <option value="Processos internos">
                      Processos internos
                    </option>
                    <option value="Finanças">Finanças</option>
                    <option value="Vendas">Vendas</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Tecnologia">Tecnologia</option>
                    {/* <option value="default">Todos</option>
                                        <option value="Soluções Internas">Soluções Internas</option>
                                        <option value="Advogado">Advogado</option>
                                        <option value="RH">RH</option>
                                        <option value="Conselheiros">Conselheiros</option>
                                        <option value="Finanças">Finanças</option>
                                        <option value="T&D">T&D</option>
                                        <option value="Processos Internos">Processos Internos</option>
                                        <option value="Contabilidade">Contabilidade</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="ERP">ERP</option>
                                        <option value="Integrador/Automatização">Integrador/Automatização</option>
                                        <option value="BI">BI</option>
                                        <option value="Infra de TI">Infra de TI</option>
                                        <option value="Pesquisa de Mercado">Pesquisa de Mercado</option>
                                        <option value="Pesquisa NPS">Pesquisa NPS</option>
                                        <option value="Tecnologia">Tecnologia</option>
                                        <option value="Growth">Growth</option>
                                        <option value="Eventos">Eventos</option>
                                        <option value="Benefícios">Benefícios</option>
                                        <option value="Bancos">Bancos</option>
                                        <option value="FDCs">FDCs</option>
                                        <option value="Imobiliário">Imobiliário</option>
                                        <option value="Corretor">Corretor</option>
                                        <option value="Tax and Labor">Tax and Labor</option>
                                        <option value="Wealth">Wealth</option>
                                        <option value="Auditoria">Auditoria</option> */}
                  </>
                )}

                {type == "company" &&
                  companies.map((item, index) => {
                    if (index == 0) {
                      return (
                        <>
                          <option value="default">Todos</option>
                          <option value={item}>{item}</option>
                        </>
                      );
                    } else {
                      return <option value={item}>{item}</option>;
                    }
                  })}
              </select>
            </div>
          )}

          {sector === "Atuação:" && (
            <div style={selectPrimary}>
              <p className="textSelectCompanyHeader">{sector}</p>
              <select
                name="select"
                onChange={onChangeType}
                style={{
                  outline: "none",
                  border: "none",
                  width: width || "200px",
                }}
              >
                <option value="default">Todos</option>
                <option value="Indústria">Indústria</option>
                <option value="Distribuidora / Revenda / Representação">
                  Distribuidora / Revenda / Representação
                </option>
                <option value="Saúde">Saúde</option>
                <option value="Seguros">Seguros</option>
                <option value="Serviços com capital financeiro intensivo">
                  Serviços com capital financeiro intensivo
                </option>
                <option value="Serviços com capital humano intensivo">
                  Serviços com capital humano intensivo
                </option>
                <option value="Serviços financeiros">
                  Serviços financeiros
                </option>
                <option value="Software e tecnologia">
                  Software e tecnologia
                </option>
                <option value="Outros">Outros</option>
              </select>
            </div>
          )}
        </div>

        {deleteItems == true ? (
          <button
            className="textBtnRemove"
            onClick={() => remove()}
            style={btnStyleRemove}
          >
            Remover
          </button>
        ) : (
          (page == "DashboardAdm" && (
            <button
              className="textBtnFilterManager"
              onClick={() => storage()}
              style={btnStyle}
            >
              Exporta dados
            </button>
          )) ||
          (page == "Journey" && (
            <button
              className="textBtnFilterManager"
              onClick={onClick}
              style={btnStyle}
            >
              Nova jornada
            </button>
          )) ||
          (page == "PartnersAdm" && (
            <button
              className="textBtnFilterManager"
              onClick={onClick}
              style={btnStyle}
            >
              Novo parceiro
            </button>
          ))
        )}
      </div>
    </div>
  );
};

export default FilterUsers;
