import React, { useState, useEffect } from 'react'
import ThowColumLayout from '../../../layouts/ThowColumLayout'
import Sidebar from '../components/Sidebar'
import HeaderDashboard from '../components/HeaderDashboard'
import FormQuestions from '../components/FormQuestions'

const InitialQuestions = () => {


    return (

        <ThowColumLayout
            page={"InitialQuestions"}
            colum1={
                <Sidebar access="adm" page="InitialQuestions" />
            }

            colum2={
                <>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "57px"
                    }}>
                        <HeaderDashboard name="Perguntas iniciais" type="adm" />
                    </div>

                    <div className="containerBox">
                        <FormQuestions />
                    </div>
                </>
            }


        >

        </ThowColumLayout>

    )

}

export default InitialQuestions