import React, { useState, useEffect } from "react";
import { convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({ value, setContract }) => {
  const [contentState, setContentState] = useState();
  const [renderContext, setRenderContext] = useState(true);

  let content = {
    entityMap: {},
    blocks: [
      {
        key: "637gr",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
  };

  const onContentStateChange = (contentState) => {
    setContract(contentState);
  };

  useEffect(() => {
    if (value != "" && renderContext == true) {
      setContentState(value);
      setRenderContext(false);
    } else if (value == null && renderContext == true) {
      setContentState(content);
      setRenderContext(false);
    } else {
      setContentState(value);
      setRenderContext(false);
    }
  }, [value]);

  return (
    <div>
      <Editor
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        editorStyle={{
          padding: "0 20px",
          maxHeight: "571px",
          overflowY: "auto",
        }}
        onContentStateChange={onContentStateChange}
        contentState={contentState}
        placeholder="Escreva algo"
      />
    </div>
  );
};

export default TextEditor;
