/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import optionsIcon from "../../../images/svg/optionsIcon.svg";
import ModalContainer from "./ModalContainer";
import ModalOptions from "./ModalOptions";

const CardPartnersAdm = ({
  id,
  image,
  type,
  description,
  benefit,
  onClick,
}) => {
  const [modal, setModal] = useState(false);

  const lineOptions = {
    display: "flex",
    flexDirection: "column",
    gap: "7px",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid #D7D7D7",
          borderRadius: "10px",
          width: "319px",
          height: "289px",
          padding: "20px",
          justifyContent: "space-between",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #E4E4EF",
            paddingBottom: "17px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            style={{ maxWidth: "50%", height: "27px", objectFit: "contain" }}
            src={image}
          />
          <img
            style={{ cursor: "pointer" }}
            onClick={() => setModal(true)}
            src={optionsIcon}
          />
        </div>

        <div style={lineOptions}>
          <p className="textTitleCardPartnersAdm">Tipo de parceiro</p>
          <p className="textContenteCardPartnersAdm">{type}</p>
        </div>
        <div style={lineOptions}>
          <p className="textTitleCardPartnersAdm">Descrição</p>
          <p className="textContenteCardPartnersAdm">{description}</p>
        </div>
        {benefit ? (
          <div style={lineOptions}>
            <p className="textTitleCardPartnersAdm">Benefício</p>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <p
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#EFF4FF",
                  border: "1px solid trasnparent",
                  borderRadius: "60PX",
                }}
                className="btnContenteCardPartnersAdm"
              >
                {benefit}
              </p>
            </div>
          </div>
        ) : (
          <div style={{ height: "50px" }}></div>
        )}
      </div>

      <ModalContainer isActive={modal} closeModal={() => setModal(!modal)}>
        <ModalOptions
          onClick={onClick}
          isActive={modal}
          edit={true}
          type="delete"
          partners={true}
          id={id}
          closeModal={() => setModal(!modal)}
        />
      </ModalContainer>
    </>
  );
};

export default CardPartnersAdm;
