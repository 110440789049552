import Button from "./Button";
import { useContext } from "react";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
import FormInitialQuestions from "./FormInitialQuestion";
import { toast } from "react-toastify";

const FormQuestions = () => {
  const {
    initialQuestions,
    setInitialQuestions,
    onSubmitDeleteInitialQuestion,
  } = useContext(DashboardContext);

  const addFieldToList = () => {
    const newInitialQuestion = {
      name: "",
      option: [
        { name: "" },
        { name: "" },
        { name: "" },
        { name: "" },
        { name: "" },
      ],
    };

    setInitialQuestions([...initialQuestions, newInitialQuestion]);
  };

  const removeFieldToList = async () => {
    const lastItem = initialQuestions[initialQuestions.length - 1];

    if (lastItem.id) {
      await onSubmitDeleteInitialQuestion(lastItem.id);
    }
  };

  const cardSucess = () => {
    toast.success(`Pergunta salva com sucesso`, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const container = {
    width: "100%",
    border: "1px solid #D7D7D7",
    marginTop: "39px",
    padding: "22px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    marginBottom: "50px",
  };

  const boxFooterAddAndRemove = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const boxAddNewRoute = {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    marginTop: "20px",
    cursor: "pointer",
  };

  return (
    <>
      <div style={container}>
        {initialQuestions.map((item, index) => {
          return (
            <FormInitialQuestions
              idOption={item?.option}
              num={index + 1}
              name={item.name}
              valueOne={item?.option[0]?.name}
              valueTwo={item?.option[1]?.name}
              valueThree={item?.option[2]?.name}
              valueFour={item?.option[3]?.name}
              valueFive={item?.option[4]?.name}
              valueSix={item?.option[5]?.name}
              valueSeven={item?.option[6]?.name}
              valueEight={item?.option[7]?.name}
              valueNine={item?.option[8]?.name}
              valueTen={item?.option[9]?.name}
              idQuestion={item?.id}
              key={item.id}
            />
          );
        })}
        <div>
          {
            <div style={boxFooterAddAndRemove}>
              <div style={boxAddNewRoute} onClick={() => addFieldToList()}>
                <div
                  style={{
                    transform: "translateY(-2px)",
                  }}
                >
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 10.7812H15"
                      stroke="#062F67"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 15.7812V5.78125"
                      stroke="#062F67"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p className="textAddNewRoute">Adicionar nova pergunta</p>
              </div>

              {initialQuestions.length > 1 && (
                <div style={boxAddNewRoute} onClick={() => removeFieldToList()}>
                  <div
                    style={{
                      transform: "translateY(-2px)",
                    }}
                  >
                    <svg
                      width="12"
                      height="3"
                      viewBox="0 0 12 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1.78125H11"
                        stroke="#EA0000"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="textRemoveNewRoute">Remover pergunta</p>
                </div>
              )}
            </div>
          }
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "28px",
          marginBottom: "50px",
        }}
      >
        <Button
          onClick={cardSucess}
          width={"240px"}
          height="45px"
          background="var(--color-primary)"
          borderRadius="60px"
          marginTop="0"
          name="Salvar perguntas inicias"
          selectBtn={"Salvar perguntas inicias"}
          border="none"
        />
      </div>
    </>
  );
};

export default FormQuestions;
