import React, { useState, useContext, useEffect } from "react";
import CardTableUsers from "./CardTableUsers";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";

const TableListUser = () => {
  const { users, usersFilter } = useContext(DashboardContext);
  const [stateCheckPrimary, setStateCheckPrimary] = useState(false);

  const styleColum1 = {
    maxWidth: "100%",
    border: "1px solid #D7D7D7",
    height: "55px",
    borderRadius: "10px",
    background: "white",
    display: "flex",
    alignItems: "center",
    padding: "0 0 0 82px",
  };

  return (
    <div className="containerBox" style={{ height: "100vh" }}>
      <ToastContainer />

      <div style={styleColum1}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>
            <p className="textNameTableListUser" style={{ width: "186px" }}>
              Nome do usuário
            </p>
          </div>

          <div>
            <p className="textNameTableListUser" style={{ width: "153px" }}>
              E-mail
            </p>
          </div>

          <div>
            <p className="textNameTableListUser" style={{ width: "131px" }}>
              Celular
            </p>
          </div>

          <div>
            <p className="textNameTableListUser" style={{ width: "150px" }}>
              Empresa
            </p>
          </div>

          <div>
            <p className="textNameTableListUser" style={{ width: "129px" }}>
              Atuação
            </p>
          </div>

          <div>
            <p className="textNameTableListUser" style={{ width: "111px" }}>
              Score
            </p>
          </div>

          <div>
            <p className="textNameTableListUser" style={{ width: "150px" }}>
              Jornada
            </p>
          </div>
        </div>
      </div>

      {usersFilter?.map((item) => {
        console.log(item);
        return (
          <CardTableUsers
            id={item.id}
            key={item.id}
            picture={item.picture}
            phone={item?.phone}
            cnpj={item?.cnpj}
            acting={item?.acting}
            company={item?.company}
            name={item.name}
            mail={item.email}
            checkNotVisible={stateCheckPrimary}
          />
        );
      })}
    </div>
  );
};

export default TableListUser;
