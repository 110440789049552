import React, { useState, useEffect } from 'react'
import ThowColumLayout from '../../../layouts/ThowColumLayout'
import Sidebar from '../components/Sidebar'
import HeaderDashboard from '../components/HeaderDashboard'
import CardSettings from '../components/CardSettings'
import { DashboardContext } from '../../../context/Dashboard/DashboardContext'
import { useContext } from 'react'

const SettingsAdm = () => {
    const {
        macro,
        setMacro,
        column,
        setColumn,
        onSubmitAddMacro,
        onSubmitGetByIdMacro,
        onSubmitUpdateMacro,
        onSubmitDeleteMacro,
        onSubmitAddColumn,
        onSubmitGetByIdColumn,
        onSubmitUpdateColumn,
        onSubmitDeleteColumn,
    } = useContext(DashboardContext);
    const [listMacro, setListMacro] = useState([])
    const [listColumn, setListColumn] = useState([1])

    /* GET LIST INDEX */
    useEffect(() => {
        macro.map((item, index) => {
            if (!item) {
                return setListMacro([1])
            }
            setListColumn([index + 1])
        })

    }, [macro])

    return (

        <ThowColumLayout
            page={"PartnersAdm"}
            colum1={
                <Sidebar access="adm" page="Settings" />
            }

            colum2={
                <>
                    <HeaderDashboard type="adm" name="Ajustes" />

                    <div className="containerBox" style={{
                        display: "flex",
                        gap: "21px",
                        marginTop: "40px"
                    }}>
                        <CardSettings
                            list={macro}
                            setList={setMacro}
                            name={"Ajuste macro"}
                            description={"Opções de macro"}
                            onAdd={onSubmitAddMacro}
                            onGetById={onSubmitGetByIdMacro}
                            onUpdate={onSubmitUpdateMacro}
                            onDelete={onSubmitDeleteMacro}
                        />
                        <CardSettings
                            list={column}
                            setList={setColumn}
                            name={"Ajuste pilar"}
                            description={"Opções de pilar"}
                            onAdd={onSubmitAddColumn}
                            onGetById={onSubmitGetByIdColumn}
                            onUpdate={onSubmitUpdateColumn}
                            onDelete={onSubmitDeleteColumn}
                        />
                    </div>
                </>
            }


        >

        </ThowColumLayout>

    )

}

export default SettingsAdm