import React, { useState, useEffect } from "react";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
import { useContext } from "react";

const InputSelectFilterManager = ({
  name,
  width,
  top,
  custom,
  color,
  marginLeft,
  height,
  type,
  onChange,
  marginRight,
  borderColor,
  maxWidth,
  selectOption,
}) => {
  const { macro, column } = useContext(DashboardContext);

  const styleSelect = {
    width: width != undefined ? width : "100%",
    maxWidth: maxWidth,
    height: height != undefined ? height : "50px",
    color: color != undefined ? color : "",
    borderRadius: "10px",
    background: "white",
    paddingLeft: "12px",
    border: `1px solid ${borderColor || "#D7D7D7"}`,
    outline: "none",
    marginBottom: "1.6875rem",
    appearance: "none",
    paddingRight: "40px",
  };

  return (
    <div
      style={{
        position: "relative",
        width: `${width != undefined ? width : "100%"}`,
        maxWidth: maxWidth,
        display: "flex",
        flexDirection: "column",
        marginRight: `${marginRight != undefined ? marginRight : "0"}`,
        marginLeft: marginLeft,
      }}
    >
      {custom == true ? (
        <label
          style={{ marginBottom: "14px" }}
          className="textPrimaryLabelInput"
        >
          {name}
        </label>
      ) : (
        <label className="textLabelInputSelectFilterManager">{name}</label>
      )}

      <select
        defaultValue={selectOption || ""}
        onChange={onChange}
        className="textSelectFilterManager"
        style={styleSelect}
      >
        <option key={"default"} value={"default"}>
          Selecione
        </option>
        {(type == "macro" &&
          macro.map((item) => {
            return (
              <>
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              </>
            );
          })) ||
          (type == "column" &&
            column.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              );
            })) ||
          (type === "business" && (
            <>
              <option value="Jurídico">Jurídico</option>
              <option value="Pessoas">Pessoas</option>
              <option value="Estratégico">Estratégico</option>
              <option value="Processos internos">Processos internos</option>
              <option value="Finanças">Finanças</option>
              <option value="Vendas">Vendas</option>
              <option value="Marketing">Marketing</option>
              <option value="Tecnologia">Tecnologia</option>
              {/* <option value="Soluções">Soluções Internas</option>
                            <option value="Advogado">Advogado</option>
                            <option value="RH">RH</option>
                            <option value="Conselheiros">Conselheiros</option>
                            <option value="Finanças">Finanças</option>
                            <option value="T">T&D</option>
                            <option value="Processos Internos">Processos Internos</option>
                            <option value="Contabilidade">Contabilidade</option>
                            <option value="Marketing">Marketing</option>
                            <option value="ERP">ERP</option>
                            <option value="Integrador/Automatização">Integrador/Automatização</option>
                            <option value="BI">BI</option>
                            <option value="Infra de TI">Infra de TI</option>
                            <option value="Pesquisa de Mercado">Pesquisa de Mercado</option>
                            <option value="Pesquisa NPS">Pesquisa NPS</option>
                            <option value="Tecnologia">Tecnologia</option>
                            <option value="Growth">Growth</option>
                            <option value="Eventos">Eventos</option>
                            <option value="Benefícios">Benefícios</option>
                            <option value="Bancos">Bancos</option>
                            <option value="FDCs">FDCs</option>
                            <option value="Imobiliário">Imobiliário</option>
                            <option value="Corretor">Corretor</option>
                            <option value="Tax and Labor">Tax and Labor</option>
                            <option value="Wealth">Wealth</option>
                            <option value="Auditoria">Auditoria</option> */}
              <option value={selectOption}>{selectOption}</option>
            </>
          ))}
      </select>

      <div
        style={{
          position: "absolute",
          right: 0,
          top: `${top != undefined ? top : "19px"}`,
          right: "19px",
        }}
      >
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.60677 6.61C5.15396 6.61 4.70115 6.40242 4.35831 5.99494L0.140695 0.982178C-0.0468983 0.759218 -0.0468983 0.39018 0.140695 0.16722C0.328288 -0.05574 0.638787 -0.05574 0.826381 0.16722L5.04399 5.17998C5.35449 5.54902 5.85905 5.54902 6.16955 5.17998L10.3872 0.16722C10.5748 -0.05574 10.8853 -0.05574 11.0729 0.16722C11.2604 0.39018 11.2604 0.759218 11.0729 0.982178L6.85524 5.99494C6.5124 6.40242 6.05959 6.61 5.60677 6.61Z"
            fill="#888888"
          />
        </svg>
      </div>
    </div>
  );
};

export default InputSelectFilterManager;
