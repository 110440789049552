import { Routes, Route, Navigate } from "react-router-dom";
import LandingAdm from "../pages/LandingAdm/Initial";
import DashboardAdm from "../pages/DashboardAdm/Initial";
import Journey from "../pages/DashboardAdm/Journeys";
import PartnersAdm from "../pages/DashboardAdm/Partners";
import SettingsAdm from "../pages/DashboardAdm/Settings";
import InitialQuestions from "../pages/DashboardAdm/InitialQuestions";
import PrivateRoute from "../pages/PrivateRoute";

export const RoutesUrl = () => {

  return (
    <Routes>

      <Route path="*" element={<LandingAdm />} />
      <Route path="/" element={<LandingAdm />} />
      <Route path="/dashboard/admin" element={
        <PrivateRoute>
          <DashboardAdm />
        </PrivateRoute>
      } />
      <Route path="/dashboard/journey/admin" element={
        <PrivateRoute>
          <Journey />
        </PrivateRoute>
      } />

      <Route path="/dashboard/partners/admin" element={
        <PrivateRoute>
          <PartnersAdm />
        </PrivateRoute>
      } />
      <Route path="/dashboard/settings/admin" element={
        <PrivateRoute>
          <SettingsAdm />
        </PrivateRoute>
      } />
      <Route path="/dashboard/questions/admin" element={
        <PrivateRoute>
          <InitialQuestions />
        </PrivateRoute>
      } />

    </Routes>
  );
};
