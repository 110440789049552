import React, { useState, useEffect, useContext } from 'react'
import ThowColumLayoutLogin from '../../../layouts/ThowColumLayoutLogin'
import loginIconAdm from '../../../images/svg/loginIconAdm.svg'
import companyIcon from '../../../images/svg/companyIcon.svg'
import api from '../../../services/api'
import { useNavigate } from 'react-router-dom'

import Button from '../components/Button'
import Input from '../components/Input'
import { AuthContext } from '../../../context/Auth/AuthContext'

const LandingAdm = () => {
    const { onSubmitFormLoginAdm } = useContext(AuthContext);
    const [mail, setMail] = useState()
    const [password, setPassword] = useState()

        /* MESSAGE ERROS */
        const [mailError, setMailError] = useState()
        const [passwordError, setPasswordError] = useState()

        /* BTN STATE */
        const [btnState, setBtnState] = useState()
    
    
        const validateEmail = (email) => {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }
    
        const getLogin = async () => {
    
            const validMail = validateEmail(mail)
    
            if (validMail == false) {
                setMailError("Email inválido")
                setBtnState(true)
    
            } else {
                setMailError()
                setBtnState(false)
    
            }

            if(password == undefined){
                setPasswordError("Insira uma senha")
                setBtnState(true)
            }
    
            if (mail != undefined && password != undefined && validMail != false) {
                const data = {
                    email: mail,
                    password: password
                }
                onSubmitFormLoginAdm(data)
            }
        }
    
        useEffect(() => {
    
            if (mail != undefined) {
    
                const validMail = validateEmail(mail)
    
                if (validMail == false) {
                    setMailError("email inválido")
                    setBtnState(true)
    
                } else {
                    setMailError()
                    setBtnState(false)
    
                }
            }

            if (password != undefined) {
                setPasswordError("")
                setBtnState(false)
            }
    
        }, [mail,password])

    const container = {
        width: "418px",
        margin: "0 auto"
    }

    const boxFooterButton = {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        marginTop: "1.3125rem"
    }

    const containerBanner = {
        position: "fixed",
        width: "45vw",
        height: "100%",
        backgroundColor: "#F8F9FF",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    const image = {
        width: "75%",
        height: "70%",
    }

    const containerColum2 = {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
    }

    return (

        <ThowColumLayoutLogin
            page={"LandingAdm"}
            colum1={
                <div style={containerBanner}>

                    <img src={loginIconAdm} style={image} />

                </div>
            }

            colum2={

                <div style={containerColum2}>

                    <div style={container}>
                        <img src={companyIcon} />

                        <h2 className="textPrimaryLanding">Acesso administrativo</h2>

                        <Input name="E-mail" error={mailError} onChange={(e) => setMail(e.target.value)} placeholder="Insira seu e-mail" type={"text"} />
                        <Input name="Senha" error={passwordError} onChange={(e) => setPassword(e.target.value)} placeholder="Insira sua senha" type={"password"} />

                        <div style={boxFooterButton}>
                            <Button width="100%" height="48px" background="var(--color-primary)"
                                borderRadius="60px" marginTop="0" name="Entrar"
                                border="none" disabled={btnState} onClick={() => getLogin()} />
                        </div>
                    </div>

                </div>

            }

        >

        </ThowColumLayoutLogin>

    )

}

export default LandingAdm