import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";

const ModalOptions = ({
  closeModal,
  type,
  isActive,
  edit,
  onClick,
  name,
  id,
  partners,
}) => {
  const { onSubmitDeleteJourney, onSubmitDestroyPartners } =
    useContext(DashboardContext);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const container = {
    display: "flex",
    flexDirection: "column",
    gap: "17px",
    backgroundColor: "#FFFFFF",
    width: "375px",
    height: "275px",
    boxShadow: "0px 0px 13px -3px #00000033",
    border: "1px solid transparent",
    borderRadius: "20px",
  };

  const headerOption = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #D7D7D7",
    padding: "15px 18px 18px",
  };

  const headerBoxLeft = {
    display: "flex",
    alignItems: "center",
    gap: "22px",
  };

  const boxTextDelete = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "18px",
    cursor: "pointer",
  };

  return (
    <div className="modalEdit" style={container}>
      {type === "delete" && confirmDelete ? (
        <>
          <div style={headerOption}>
            <div style={headerBoxLeft}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setConfirmDelete(false);
                  closeModal();
                }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                    fill="black"
                  />
                </svg>
              </div>
              <p className="textModalTitleOption">Excluir tabela</p>
            </div>

            <Button
              width={"80px"}
              height="37px"
              background="#FFE1E1"
              color="#EA0000"
              borderRadius="60px"
              marginTop="0"
              name="Excluir"
              selectBtn={"Excluir"}
              border="none"
              onClick={() =>
                partners
                  ? onSubmitDestroyPartners(id)
                  : onSubmitDeleteJourney(id)
              }
            />
          </div>

          <p style={{ textAlign: "center", marginTop: "46px" }}>
            Tem certeza que deseja excluir <b>{name}</b>
          </p>
        </>
      ) : (
        <>
          <div style={headerOption}>
            <p className="textModalTitleOption">Ações</p>
            <div onClick={closeModal}>
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.48406 6.36766L12.4871 1.35916C12.7976 1.04823 12.7976 0.544109 12.4871 0.2332C12.1765 -0.0777335 11.673 -0.0777335 11.3624 0.2332L6.35997 5.24222L1.35752 0.2332C1.04696 -0.0777335 0.543453 -0.0777335 0.232919 0.2332C-0.0776148 0.544134 -0.0776396 1.04825 0.232919 1.35916L5.23589 6.36766L0.232919 11.3762C-0.0776396 11.6871 -0.0776396 12.1912 0.232919 12.5021C0.543478 12.8131 1.04699 12.8131 1.35752 12.5021L6.35997 7.4931L11.3624 12.5021C11.673 12.8131 12.1765 12.8131 12.487 12.5021C12.7976 12.1912 12.7976 11.6871 12.487 11.3762L7.48406 6.36766Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          {edit && (
            <div
              style={boxTextDelete}
              onClick={() => {
                setConfirmDelete(false);
                closeModal();
                onClick();
              }}
            >
              <p className="textModalContentOptionEdit">Editar</p>
            </div>
          )}
          <div style={boxTextDelete} onClick={() => setConfirmDelete(true)}>
            <p className="textModalContentOption">Excluir tabela</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ModalOptions;
