import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

export const AuthContext = createContext({});

export const AuthValidation = ({ children }) => {
  const [checkTerms, setCheckTerms] = useState(false);
  const [email, setEmail] = useState("email@mail.com");
  const navigate = useNavigate();

  /* ADMIN */
  const onSubmitFormLoginAdm = async (data) => {
    try {
      const response = await api.post("/session/admin", data);
      const { token } = response.data;

      localStorage.setItem("@QuboAdmn:", token);
      navigate("/dashboard/admin", { replace: true });
    } catch (err) {
      console.log(err);
      const message = err?.response?.data?.error;
      toast.error(`${message || "Estamos com alguns problemas técnicos"}`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <AuthContext.Provider
        value={{
          checkTerms,
          setCheckTerms,
          email,
          onSubmitFormLoginAdm,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};
