import { useState } from "react";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
import { useContext } from "react";
import CardAddOptionList from "./CardAddOptionList";
import Input from "./Input";

const FormInitialQuestions = ({
  valueOne,
  valueTwo,
  valueThree,
  valueFour,
  valueFive,
  idQuestion,
  idOption,
  num,
  name,
  valueSix,
  valueSeven,
  valueEight,
  valueNine,
  valueTen,
}) => {
  const {
    onSubmitAddInitialQuestion,
    onSubmitUpdateInitialQuestion,
    onSubmitUpdateInitialQuestionOption,
  } = useContext(DashboardContext);

  const [nameInitialQuestion, setNameInitialQuestion] = useState(name);

  const [valueOptionOne, setValueOptionOne] = useState(valueOne);
  const [valueOptionTwo, setValueOptionTwo] = useState(valueTwo);
  const [valueOptionThree, setValueOptionThree] = useState(valueThree);

  const [valueOptionFour, setValueOptionFour] = useState(valueFour);
  const [valueOptionFive, setValueOptionFive] = useState(valueFive);
  const [valueOptionSix, setValueOptionSix] = useState(valueSix);

  const [valueOptionSeven, setValueOptionSeven] = useState(valueSeven);
  const [valueOptionEight, setValueOptionEight] = useState(valueEight);
  const [valueOptionNine, setValueOptionNine] = useState(valueNine);

  const [valueOptionTen, setValueOptionTen] = useState(valueTen);

  /* CREATE OR UPDATE INITIAL QUESTION */
  const onChangeInitialQuestion = async (value, idQuestion, setValue) => {
    if (idQuestion) {
      return onSubmitUpdateInitialQuestion(idQuestion, { name: value });
    } else if (!idQuestion) {
      return onSubmitAddInitialQuestion({ name: value });
    }
  };

  /* CREATE OR UPDATE OPTION */
  const onChangeInitialQuestionOption = async (
    value,
    idQuestion,
    idOption,
    setValue
  ) => {
    if (idQuestion && idOption) {
      return onSubmitUpdateInitialQuestionOption(idOption, { name: value });
    } else if (idQuestion && !idOption) {
      return onSubmitUpdateInitialQuestion(idQuestion, {
        options: [{ name: value }],
      });
    }
  };

  const container = {
    width: "100%",
  };

  const boxHeaderQuestion = {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  };

  return (
    <div style={container}>
      <div style={boxHeaderQuestion}>
        <h3 className="textTitleFormJourney">Pergunta {num}</h3>
        <Input
          onChange={(e) => setNameInitialQuestion(e.target.value)}
          onBlur={(e) =>
            onChangeInitialQuestion(
              e.target.value,
              idQuestion,
              setNameInitialQuestion
            )
          }
          value={nameInitialQuestion}
          width="100%"
          name="Pergunta"
          placeholder="Insira uma pergunta pergunta"
          type={"text"}
        />
      </div>

      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionOne(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[0]?.id,
            setValueOptionOne
          )
        }
        value={valueOptionOne}
        key={1}
        placeholder={"Insira uma pergunta pergunta"}
        num={1}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionTwo(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[1]?.id,
            setValueOptionTwo
          )
        }
        value={valueOptionTwo}
        key={2}
        placeholder={"Insira uma pergunta pergunta"}
        num={2}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionThree(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[2]?.id,
            setValueOptionThree
          )
        }
        value={valueOptionThree}
        key={3}
        placeholder={"Insira uma pergunta pergunta"}
        num={3}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionFour(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[3]?.id,
            setValueOptionFour
          )
        }
        value={valueOptionFour}
        key={4}
        placeholder={"Insira uma pergunta pergunta"}
        num={4}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionFive(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[4]?.id,
            setValueOptionFive
          )
        }
        value={valueOptionFive}
        key={5}
        placeholder={"Insira uma pergunta pergunta"}
        num={5}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionSix(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[5]?.id,
            setValueOptionSix
          )
        }
        value={valueOptionSix}
        key={6}
        placeholder={"Insira uma pergunta pergunta"}
        num={6}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionSeven(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[6]?.id,
            setValueOptionSeven
          )
        }
        value={valueOptionSeven}
        key={7}
        placeholder={"Insira uma pergunta pergunta"}
        num={7}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionEight(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[7]?.id,
            setValueOptionEight
          )
        }
        value={valueOptionEight}
        key={8}
        placeholder={"Insira uma pergunta pergunta"}
        num={8}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionNine(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[8]?.id,
            setValueOptionNine
          )
        }
        value={valueOptionNine}
        key={9}
        placeholder={"Insira uma pergunta pergunta"}
        num={9}
        notTrash={true}
      />
      <CardAddOptionList
        disabled={String(nameInitialQuestion).length > 0 ? false : true}
        onChange={(e) => setValueOptionTen(e.target.value)}
        onBlur={(e) =>
          onChangeInitialQuestionOption(
            e.target.value,
            idQuestion,
            idOption[9]?.id,
            setValueOptionTen
          )
        }
        value={valueOptionTen}
        key={10}
        placeholder={"Insira uma pergunta pergunta"}
        num={10}
        notTrash={true}
      />
    </div>
  );
};

export default FormInitialQuestions;
