import { useState } from "react";
import Switch from "react-switch";
import optionsIcon from "../../../images/svg/optionsIcon.svg"
import Button from "./Button";
import ModalContainer from "./ModalContainer";
import ModalOptions from "./ModalOptions";

const CardJourney = ({ name, date, questions, onClick, id}) => {
    const [checked, setChecked] = useState(true)
    const [modal, setModal] = useState(false)

    const handleChange = () => {
        setChecked(!checked);
    }

    const container = {
        display: "flex",
        padding: "20px 22px",
        border: "1px solid #D7D7D7",
        borderRadius: "10px",
        backgroundColor: "#fff",
        margin: "0 0 13px 0"
    }
    const boxCardRigth = {
        display: "flex",
        alignItems: "center",
        gap: "30px",
        width: "30%",
    }
    const cardContentRigth = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
    }
    const contentRight = {
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        justifyContent: "center",
        alignItems: "center"
    }
    const boxCardLeft = {
        width: "70%",
        marginLeft: "10%",
        display: "flex",
        justifyContent: "space-between"
    }

    return (
        <>
            <div style={container}>
                <div style={boxCardRigth}>
                    <Switch onColor="#102033" offColor="#CDCDCD" height={17} handleDiameter={12} width={35} uncheckedIcon={false} checkedIcon={false} onChange={handleChange} checked={checked} />
                    <div style={cardContentRigth}>
                        <p className="textCardJourneyTitle">{name}</p>
                        <p className="textCardJourneyContent">Nome da jornada</p>
                    </div>
                </div>

                <div style={{ borderRight: "1px solid #79797980", }}></div>

                <div style={boxCardLeft}>
                    <div style={{
                        display: "flex",
                        gap: "50px"
                    }}>

                        <div style={contentRight}>
                            <p className="textCardJourneyTitle">{date}</p>
                            <p className="textCardJourneyContent">Data de criação</p>
                        </div>

                        <div style={contentRight}>
                            <p className="textCardJourneyTitle">{questions}</p>
                            <p className="textCardJourneyContent">N° de perguntas</p>
                        </div>
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        gap: "54px"
                    }}>
                        <div onClick={onClick}>
                            <Button width={"164px"} height="37px" background="var(--color-primary)"
                                borderRadius="60px" marginTop="0" name="Visualizar" selectBtn={"Visualizar"}
                                border="none" />
                        </div>
                        <img onClick={()=> setModal(true)} style={{ cursor: "pointer" }} src={optionsIcon} />
                    </div>
                </div>
            </div>

            <ModalContainer isActive={modal} closeModal={() => setModal(!modal)}>
                <ModalOptions isActive={modal} type="delete" id={id} name={name} closeModal={() => setModal(!modal)} />
            </ModalContainer >
        </>
    )
}

export default CardJourney