import CardAddOptionList from "./CardAddOptionList"

const CardSettings = ({
    list,
    setList,
    name,
    description,
    onAdd,
    onGetById,
    onUpdate,
    onDelete,
}) => {

    /* ADD AN INPUT FIELDT */
    const addFieldToList = () => {
        const lastItem = { name: "" }
        setList([...list, lastItem])
    }

    /* CHANGE DO INPUT */
    const onChangeNameSetting = (e, id, index) => {
        let value = e.target.value
        let newList = list
        newList.map(item => {
            if (item.id == id) {
                item.name = value
            }
        })
        setList([...newList])

        onSubmitAddSetting(e, index, value)
    }

    /* ADD AND EDIT SETTING */
    const onSubmitAddSetting = async (e, index, value) => {
        const id = e.target.id

        const itemExists = await onGetById(id)

        if (itemExists?.data?.length > 0) {
            const data = {
                name: list[index] = value
            }
            onUpdate(id, data)
        } else if (itemExists?.length == 0) {

            const data = {
                name: list[index] = value
            }

            onAdd(data)
        }
    }

    /* DELETE SETTING */
    const onSubmitDeleteSetting = async (id) => {

        const itemExists = await onGetById(id)
        if (itemExists?.data?.length > 0) {
            await onDelete(id)
        }
    }

    return (
        <div style={{
            width: "50%",
            border: "1px solid #D7D7D7",
            borderRadius: "8px",
            backgroundColor: "#fff",
            height: "652px"
        }}>
            <div style={{
                padding: "21px 16px",
                borderBottom: "1px solid #D7D7D7",
                marginBottom: "12px"
            }}>
                <h3 className="textTitleContract">{name}</h3>
            </div>
            <div style={{
                padding: "7px 39px 19px 16px",
                overflowY: "auto",
                height: "550px"
            }}>
                <h4 className="textTitleContract">{description}</h4>

                {
                    list.length > 0
                        ?
                        list.map((item, index) => {
                            return (
                                <CardAddOptionList typeValue="virtual" index={index} id={item.id} key={index} num={index + 1} onBlur={(e) => onChangeNameSetting(e, item.id, index)} value={item.name} onClick={() => onSubmitDeleteSetting(item.id)} />
                            )
                        })
                        :
                        <CardAddOptionList typeValue="virtual" num={1} onBlur={(e) => onChangeNameSetting(e)} value={""} />

                }

                <div style={{ display: "flex", alignItems: "center", gap: "12px", cursor: "pointer" }} onClick={() => addFieldToList()}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 10H15" stroke="#102033" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M10 15V5" stroke="#102033" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>


                    <p className='textAddNewRoute'>Adicionar nova opção</p>
                </div>
            </div>

        </div>
    )
}

export default CardSettings