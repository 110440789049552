import { useEffect, useState } from "react";
import uploadIcon from "../../../images/svg/uploadIcon.svg"
import { toast } from "react-toastify";
import deleteImageIcon from "../../../images/svg/deleteImageIcon2.svg"

const UploadBanner = ({
    imagesFirebase,
    setImagesFirebase,
    index
}) => {
    const [imagesBase64,setImagesBase64] = useState([])

    /* IMAGE DELETION FUNCTION */
    const deleteFile = (file) => {
        const filterImgs = imagesBase64.filter((img) => img != file)
        setImagesBase64(filterImgs)
    }

    /* FUNCTION TO ADD IMAGES */
    const getImages = async (file) => {
        if (imagesBase64.length >= 4) {
            toast.error('Limite de imagens atingido!', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            return false
        }

        const reader = new FileReader()

        reader.readAsDataURL(file)

        console.log(file)
        reader.onload = () => {

            const array = [...imagesBase64]

            array.push({ image: reader.result })

            setImagesBase64(array)
            
        }

    }

    /* STYLES COMPONENTS */
    const container = {
        marginTop: "20px",
        border: '2px dashed #D7D7D7',
        borderRadius: "8px",
        width: "100%"
    }

    const boxContent = {
        display: "flex",
        justifyContent: "center",
        padding: "8px",
    }

    const boxIcon = {
        display: "flex",
        alignItems: "center",
        gap: '13px',
        cursor: "pointer",
    }

    const boxImgAndIcon = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    }

    return (
        <>
            <div style={container}>
                <div style={boxContent}>
                    <div style={boxImgAndIcon}>
                        <label htmlFor="fileImage" style={boxIcon}>
                            <img src={uploadIcon} />
                            <p style={{ transform: "translateY(3px)" }} className='textUploadImg'>Upload de imagens comerciais</p>
                        </label>
                        <div style={{ display: "flex" }}>
                            {imagesBase64?.map((item, index) => {
                                return (
                                    <div key={index} style={{ position: "relative", marginRight: "3rem", marginLeft: "0.7rem" }}>
                                        <img src={"data:image/;base64;" + item.image} style={{ width: "100px", height: "100px", borderRadius: "3px", marginTop: "32px" }} />

                                        <img onClick={() => deleteFile(item)} src={deleteImageIcon} style={{ position: "absolute", top: "20px", right: "-10px", background: "white", zIndex: "1px", borderRadius: "30px", cursor: "pointer" }} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <input onChange={(e) => getImages(e.target.files[0])} accept='image/png' name="fileImage" id="fileImage" type="file" style={{ display: "none" }} />
        </>
    )
}

export default UploadBanner