import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./styles/animations.css";
import "./styles/hover.css";
import "./styles/active.css";
import { RoutesUrl } from "./routes/routes";
import { AuthValidation } from "./context/Auth/AuthContext";
import { ToastContainer } from "react-toastify";
import { DashboardValidation } from "./context/Dashboard/DashboardContext";
import UserProvider from "./context/User";
import ModalProvider from "./context/Modal";

const App = () => {
  return (
    <>
      <DashboardValidation>
        <UserProvider>
          <ModalProvider>
            <AuthValidation>
              <RoutesUrl />
            </AuthValidation>
          </ModalProvider>
        </UserProvider>
      </DashboardValidation>
      <ToastContainer />
    </>
  );
};

export default App;
