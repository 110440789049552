import React, { useState, useEffect, useContext } from 'react'
import ThowColumLayout from '../../../layouts/ThowColumLayout'
import Sidebar from '../components/Sidebar'
import HeaderDashboard from '../components/HeaderDashboard'
import 'react-circular-progressbar/dist/styles.css';
import FilterUsers from '../components/FilterUsers'
import CardJourney from '../components/CardJourney';
import FormAddJourney from '../components/FormAddJourney';
import { DashboardContext } from '../../../context/Dashboard/DashboardContext';


const Journey = () => {
    const [formJourneyName, setFormJourneyName] = useState(false)
    const [formJourneyNameEdid, setFormJourneyNameEdit] = useState(false)
    const { journey, journeyFilter, getFilterJourneySearch } = useContext(DashboardContext);
    const [id, setId] = useState()

    console.log(journey)

    return (

        <ThowColumLayout
            page={"Journey"}
            colum1={
                <Sidebar access="adm" page="Journey" />
            }

            colum2={
                <>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "57px"
                    }}>
                        <HeaderDashboard type="adm" onClick={() => {
                            setFormJourneyName(false)
                            setFormJourneyNameEdit(false)
                        }} name="Jornadas" btnBack={formJourneyNameEdid ? true : false || formJourneyName ? true : false} />

                        {formJourneyNameEdid || !formJourneyName && <FilterUsers onChangeSearch={(e) => getFilterJourneySearch(e.target.value)} placeholder={"Pesquisar jornada"} page="Journey" onClick={() => {
                            setFormJourneyName(true)
                            setFormJourneyNameEdit(false)
                        }} />}

                    </div>
                    <div className="containerBox">
                        {formJourneyName ?
                            (
                                /* CREATE NEW JORNEY */
                                <>
                                    <FormAddJourney key={"FormAddJourney"} edit={false} />
                                </>
                            )
                            :
                            formJourneyNameEdid ?
                                (
                                    /* EDIT NEW JORNEY */
                                    <>
                                        <FormAddJourney key={"FormAddJourney"} idJourney={id} edit={true} />
                                    </>
                                )
                                :
                                (
                                    journeyFilter.map(item => {
                                        let day = item.createdAt.split("-").reverse().splice(0, 1).join("").split("T")[0]
                                        let monthAndYear = item.createdAt.split("-").reverse().splice(1, 2)
                                        let date = monthAndYear
                                        date.unshift(day)
                                        date = date.join("/")

                                        return (
                                            <CardJourney onClick={() => {
                                                console.log(item.id)
                                                setId(item.id)
                                                setFormJourneyNameEdit(true)
                                            }} key={item.id} name={item.name} date={date} questions={item.questions.length} id={item.id} />
                                        )
                                    })
                                )
                        }
                    </div>

                </>
            }


        >

        </ThowColumLayout>

    )

}

export default Journey