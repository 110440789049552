import React, { useState, useEffect } from "react";
import ThowColumLayout from "../../../layouts/ThowColumLayout";
import Sidebar from "../components/Sidebar";
import HeaderDashboard from "../components/HeaderDashboard";
import FilterUsers from "../components/FilterUsers";
import company1 from "../../../images/svg/company1.svg";
import company2 from "../../../images/svg/company2.svg";
import company3 from "../../../images/svg/company3.svg";
import company4 from "../../../images/svg/company4.svg";
import company5 from "../../../images/svg/company5.svg";
import CardPartnersAdm from "../components/CardPartnersAdm";
import EditPage from "../components/EditPage";
import { useContext } from "react";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";

const PartnersAdm = () => {
  const { partners, filterPartner, getFilterPartner, getFilterPartnerSearch } =
    useContext(DashboardContext);

  /* SECTIONS PAGE */
  const [editPartner, setEditPartner] = useState(false);
  const [idPartner, setIdPartner] = useState("");
  const [addPartner, setAddPartner] = useState(false);

  const listContracts = [
    {
      id: "2",
      image: company1,
      type: "Tecnologia",
      description: "Plataforma de criação de apps & softwares personalizados.",
      benefit: "30% de desconto",
    },
    {
      id: "3",
      image: company2,
      type: "Tecnologia",
      description:
        "Clicksign, empresa especialista em assinatura eletrônica e digital.",
      benefit: "23% de desconto",
    },
    {
      id: "4",
      image: company3,
      type: "Finanças",
      description:
        "Clicksign, empresa especialista em assinatura eletrônica e digital.",
      benefit: "23% de desconto",
    },
    {
      id: "5",
      image: company4,
      type: "Tecnologia",
      description:
        "Clicksign, empresa especialista em assinatura eletrônica e digital.",
      benefit: "23% de desconto",
    },
    {
      id: "6",
      image: company5,
      type: "ERP",
      description:
        "Clicksign, empresa especialista em assinatura eletrônica e digital.",
      benefit: "23% de desconto",
    },
  ];

  return (
    <ThowColumLayout
      page={"PartnersAdm"}
      colum1={<Sidebar access="adm" page="Partners" />}
      colum2={
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "57px",
            }}
          >
            <HeaderDashboard
              type="adm"
              onClick={() => {
                setEditPartner(false);
                setAddPartner(false);
              }}
              name={
                (editPartner && "Editar parceiro") ||
                (addPartner && "Novo parceiro") ||
                "Parceiros"
              }
              btnBack={editPartner || addPartner || false}
            />

            {!editPartner && !addPartner && (
              <FilterUsers
                placeholder={"Pesquisar parceiro"}
                type={"partner"}
                onClick={() => setAddPartner(true)}
                onChangeSearch={(e) => getFilterPartnerSearch(e.target.value)}
                onChangeType={(e) => getFilterPartner(e.target.value)}
                select
                width="auto"
                widthAlternative={true}
                name="Tipo de parceiro:"
                page="PartnersAdm"
              />
            )}
          </div>

          <div
            className="containerBox"
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              marginBottom: "50px",
            }}
          >
            {(addPartner && (
              <EditPage
                type={"added"}
                home={() => {
                  setEditPartner(false);
                  setAddPartner(false);
                }}
              />
            )) ||
              (editPartner && idPartner && (
                <EditPage
                  type={"edit"}
                  id={idPartner}
                  home={() => {
                    setEditPartner(false);
                    setAddPartner(false);
                  }}
                />
              )) ||
              filterPartner?.map((item) => {
                let description = [];

                item?.partner?.description?.split("").map((res) => {
                  if (description.length <= 70) {
                    description.push(res);
                  } else if (description.length == 71) {
                    description = `${description.join("")}...`;
                  }
                });

                return (
                  <CardPartnersAdm
                    onClick={() => {
                      setEditPartner(true);
                      setIdPartner(item.partner.id);
                    }}
                    benefit={item.partner.benefits}
                    id={item.partner.id}
                    type={item.partner.type}
                    description={description}
                    key={item.partner.id}
                    image={item.partner.picture}
                  />
                );
              })}
          </div>
        </>
      }
    ></ThowColumLayout>
  );
};

export default PartnersAdm;
