import React, { useEffect, useContext } from "react";
import { BigHead } from "@bigheads/core";
import { ModalContext } from "../../../context/Modal";
import { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import api from "../../../services/api";

const CardTableUsers = ({
  checkNotVisible,
  name,
  mail,
  picture,
  phone,
  company,
  cnpj,
  id,
  acting,
}) => {
  const [nameCard, setNameCard] = useState(name);
  const [mailCard, setMailCard] = useState(mail);
  const [phoneCard, setPhoneCard] = useState(phone || "-");

  const [companyCard, setCompanyCard] = useState(company);
  const [cnpjCard, setCnpjCard] = useState(cnpj);
  const [actingCard, setActingCard] = useState(acting);
  const [journeyCard, setJourneyCard] = useState(50);

  const [percentage, setPercentage] = useState(0);
  const [percentageQuestion, setPercentageQuestion] = useState(0);

  useEffect(() => {
    const reduceSize = (item, size) => {
      let itemList = [];

      console.log(item);
      item?.split("")?.map((res) => {
        if (itemList.length <= size) {
          itemList.push(res);
        } else if (itemList.length == size + 1) {
          itemList = `${itemList.join("")}...`;
        }
      });

      return itemList;
    };

    if (nameCard) {
      setNameCard(reduceSize(nameCard, 10));
      setMailCard(reduceSize(mailCard, 15));
      setPhoneCard(reduceSize(phoneCard, 12));
      setCompanyCard(reduceSize(companyCard, 10));
      setCnpjCard(reduceSize(cnpjCard, 20));
      setActingCard(reduceSize(actingCard, 10));
    }
  }, [name]);

  const { removeAllItems } = useContext(ModalContext);

  const styleColum2 = {
    maxWidth: "100%",
    border: "1px solid #D7D7D7",
    height: "55px",
    marginTop: "1.26rem",
    borderRadius: "10px",
    background: "white",
    display: "flex",
  };

  const changeTypeBtn = () => {
    removeAllItems(checkNotVisible);
  };

  const getPercent = async () => {
    const token = localStorage.getItem("@QuboAdmn:");

    let config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    if (id) {
      try {
        const percentUserScore = await api.get(`/score/client/${id}`, config);

        if (percentUserScore.data?.score) {
          setPercentage(percentUserScore.data?.score);
        } else {
          setPercentage(0);
        }
      } catch (error) {}

      try {
        const percentUserQuestion = await api.get(
          `/percentage/question/client/${id}`,
          config
        );

        if (percentUserQuestion.data?.percentage) {
          setPercentageQuestion(percentUserQuestion.data?.percentage);
        } else {
          setPercentageQuestion(0);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getPercent();
  }, [id]);

  useEffect(() => {
    changeTypeBtn();
  }, [checkNotVisible]);

  return (
    <div style={styleColum2}>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "20px",
            marginRight: "20px",
            width: "45px",
            height: "100%",
            borderRadius: "100%",
          }}
        >
          {!picture ? (
            <div style={{ width: "100%", height: "100%" }}>
              <BigHead
                accessory="shades"
                body="chest"
                circleColor="blue"
                clothing="tankTop"
                clothingColor="black"
                eyebrows="angry"
                eyes="wink"
                facialHair="mediumBeard"
                graphic="vue"
                hair="short"
                hairColor="black"
                hat="none"
                hatColor="green"
                lashes="false"
                lipColor="purple"
                mask="true"
                faceMask="true"
                mouth="open"
                skinTone="brown"
              />
            </div>
          ) : (
            <img
              src={picture}
              style={{ width: "35px", height: "35px", borderRadius: "100px" }}
            />
          )}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "150px",
          }}
        >
          <p className="textNameTableListUser">{nameCard}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "168px",
          }}
        >
          <p className="textNameTableListUser">{mailCard}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "150px",
          }}
        >
          <p className="textNameTableListUser">{phoneCard}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "150px",
          }}
        >
          <p className="textNameTableListUser">{companyCard}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "120px",
          }}
        >
          <p className="textNameTableListUser">{actingCard}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "150px",
            marginTop: "5px",
          }}
        >
          <div style={{ width: "45px", height: "auto" }}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              background
              backgroundPadding={6}
              styles={buildStyles({
                backgroundColor: "#fff",
                textColor: "var(--color-primary)",
                pathColor: "var(--color-primary)",
                trailColor: "#F7F9FB",
              })}
            />
          </div>
          <div style={{ width: "45px", height: "auto" }}>
            <CircularProgressbar
              value={percentageQuestion}
              text={`${percentageQuestion}%`}
              background
              backgroundPadding={6}
              styles={buildStyles({
                backgroundColor: "#fff",
                textColor: "var(--color-primary)",
                pathColor: "var(--color-primary)",
                trailColor: "#F7F9FB",
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTableUsers;
