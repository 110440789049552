import { useContext, useEffect } from "react";
import { useState } from "react";
import { DashboardContext } from "../../../context/Dashboard/DashboardContext";
import uploadIconSmall from "../../../images/svg/uploadIconSmall.svg";
import destroyIconSmall from "../../../images/svg/destroyIcon.svg";
import Input from "./Input";
import InputSelectFilterManager from "./InputSelectFilterManager";
import { toast } from "react-toastify";
import app from "../../../firebase/database";
import iconDelete from "../../../images/svg/trashIcon.svg";
import { v4 as uuidv4 } from "uuid";

const CardJourneyList = ({
  indexQuest,
  questionId,
  nameQuestion,
  macroQuestion,
  columnQuestion,
  orderQuestion,
  positiveTaskQuestion,
  positivePartnerQuestion,
  positiveContentQuestion,
  positivePointsQuestion,
  partialTaskQuestion,
  partialPartnerQuestion,
  partialContentQuestion,
  partialPointsQuestion,
  negativeTaskQuestion,
  negativePartnerQuestion,
  negativeContentQuestion,
  negativePointsQuestion,
  newId,
  notJourney,
  notQuestion,
  positiveId,
  partialId,
  negativeId,
  destroy,
}) => {
  const { onSubmitUpdateQuestion, onSubmitAddQuestion } =
    useContext(DashboardContext);

  /* STATE QUESTION */
  const [nameQuestionState, setNameQuestionState] = useState(nameQuestion);
  const [macroQuestionState, setMacroQuestionState] = useState(macroQuestion);
  const [columnQuestionState, setColumnQuestionState] =
    useState(columnQuestion);

  const [orderQuestionState, setOrderQuestionState] = useState(orderQuestion);

  /* POSITIVE STATE */
  const [positiveTaskQuestionState, setPositiveTaskQuestionState] =
    useState(positiveTaskQuestion);
  const [positivePartnerQuestionState, setPositivePartnerQuestionState] =
    useState(positivePartnerQuestion);
  const [positiveContentQuestionState, setPositiveContentQuestionState] =
    useState(positiveContentQuestion);

  const [positiveUuid, setPositiveUuid] = useState("");
  const [positivePointsQuestionState, setPositivePointsQuestionState] =
    useState(positivePointsQuestion);

  /* PARTIAL STATE */
  const [partialTaskQuestionState, setPartialTaskQuestionState] =
    useState(partialTaskQuestion);
  const [partialPartnerQuestionState, setPartialPartnerQuestionState] =
    useState(partialPartnerQuestion);
  const [partialContentQuestionState, setPartialContentQuestionState] =
    useState(partialContentQuestion);

  const [partialUuid, setPartialUuid] = useState("");
  const [partialPointsQuestionState, setPartialPointsQuestionState] = useState(
    partialPointsQuestion
  );

  /* NEGATIVE STATE */
  const [negativeTaskQuestionState, setNegativeTaskQuestionState] =
    useState(negativeTaskQuestion);
  const [negativePartnerQuestionState, setNegativePartnerQuestionState] =
    useState(negativePartnerQuestion);
  const [negativeContentQuestionState, setNegativeContentQuestionState] =
    useState(negativeContentQuestion);

  const [negativeUuid, setNegativeUuid] = useState("");
  const [negativePointsQuestionState, setNegativePointsQuestionState] =
    useState(negativePointsQuestion);

  const valueAndType = (e, type, section) => {
    return {
      value: e?.target?.value || "",
      type: type,
      section: section,
    };
  };

  /* CHANGE DO INPUT QUESTION CREATED OR  UPDATE */
  const onChangeQuestionCreated = async (questionInput, id, idContent) => {
    const value = questionInput?.value;

    if (questionInput.type == "name") {
      setNameQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, { name: value });
      }
      if (!newId) {
        return toast.error("Forneça o nome da jornada.", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      return onSubmitAddQuestion(newId, { name: value });
    }
    if (questionInput.type == "macro") {
      setMacroQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, { macro: value });
      }
      return onSubmitAddQuestion(newId, { macro: value });
    }
    if (questionInput.type == "column") {
      setColumnQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, { column: value });
      }
      return onSubmitAddQuestion(newId, { column: value });
    }
    if (questionInput.type == "order") {
      setOrderQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, { order: value });
      }
      return onSubmitAddQuestion(newId, { order: value });
    }

    /* POSITIVE */
    if (questionInput.type == "positive" && questionInput.section == "task") {
      setPositiveTaskQuestionState(value);
      if (id) {
        return await onSubmitUpdateQuestion(id, {
          positive: { task: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        positive: { task: value, type: questionInput.type },
      });
    }
    if (
      questionInput.type == "positive" &&
      questionInput.section == "partner"
    ) {
      setPositivePartnerQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          positive: { partner: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        positive: { partner: value, type: questionInput.type },
      });
    }

    if (
      questionInput.type == "positive" &&
      questionInput.section == "content"
    ) {
      setPositiveContentQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          positive: {
            content: value,
            type: questionInput.type,
            idContent: positiveId,
          },
        });
      }
      return onSubmitAddQuestion(newId, {
        positive: { content: value, type: questionInput.type },
      });
    }

    if (questionInput.type == "positive" && questionInput.section == "points") {
      setPositivePointsQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          positive: { points: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        positive: { points: value, type: questionInput.type },
      });
    }

    /* PARTIAL */
    if (questionInput.type == "partial" && questionInput.section == "task") {
      setPartialTaskQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          partial: { task: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        partial: { task: value, type: questionInput.type },
      });
    }
    if (questionInput.type == "partial" && questionInput.section == "partner") {
      setPartialPartnerQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          partial: { partner: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        partial: { partner: value, type: questionInput.type },
      });
    }
    if (questionInput.type == "partial" && questionInput.section == "content") {
      setPartialContentQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          partial: {
            content: value,
            type: questionInput.type,
            idContent: partialId,
          },
        });
      }
      return onSubmitAddQuestion(newId, {
        partial: { content: value, type: questionInput.type },
      });
    }
    if (questionInput.type == "partial" && questionInput.section == "points") {
      setPartialPointsQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          partial: { points: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        partial: { points: value, type: questionInput.type },
      });
    }

    /* NEGATIVE */
    if (questionInput.type == "negative" && questionInput.section == "task") {
      setNegativeTaskQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          negative: { task: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        negative: { task: value, type: questionInput.type },
      });
    }
    if (
      questionInput.type == "negative" &&
      questionInput.section == "partner"
    ) {
      setNegativePartnerQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          negative: { partner: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        negative: { partner: value, type: questionInput.type },
      });
    }
    if (
      questionInput.type == "negative" &&
      questionInput.section == "content"
    ) {
      setNegativeContentQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          negative: {
            content: value,
            type: questionInput.type,
            idContent: negativeId,
          },
        });
      }
      return onSubmitAddQuestion(newId, {
        negative: { content: value, type: questionInput.type },
      });
    }
    if (questionInput.type == "negative" && questionInput.section == "points") {
      setNegativePointsQuestionState(value);
      if (id) {
        return onSubmitUpdateQuestion(id, {
          negative: { points: value, type: questionInput.type },
        });
      }
      return onSubmitAddQuestion(newId, {
        negative: { points: value, type: questionInput.type },
      });
    }
  };

  const loadImagePositive = async (file) => {
    if (file) {
      setPositiveContentQuestionState(file.name);
      const reader = new FileReader();
      reader.onload = function (event) {
        // O resultado é armazenado em event.target.result
        const base64String = event.target.result;

        onSubmitUpdateQuestion(questionId, {
          positive: {
            contentFile: base64String,
            content: file.name,
            type: "positive",
            name: file.name,
            type_file: file.type,
            idType: positiveId,
          },
        });
      };
      reader.readAsDataURL(file);
    }
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = async () => {};

    // await app
    //   .storage()
    //   .ref(`/content/${file.name}`)
    //   .put(file)
    //   .then(() => {
    //     app
    //       .storage()
    //       .ref(`/content/${file.name}`)
    //       .getDownloadURL()
    //       .then(async (url) => {
    //         onSubmitUpdateQuestion(questionId, {
    //           positive: { content: url, type: "positive" },
    //         });
    //       });
    //   });
  };

  const loadImagePartial = async (file) => {
    if (file) {
      setPartialContentQuestionState(file.name);
      const reader = new FileReader();
      reader.onload = function (event) {
        const base64String = event.target.result;

        onSubmitUpdateQuestion(questionId, {
          partial: {
            contentFile: base64String,
            content: file.name,
            type: "partial",
            name: file.name,
            type_file: file.type,
            idType: partialId,
          },
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const loadImageNegative = async (file) => {
    console.log(file, 878778);
    if (file) {
      const reader = new FileReader();
      setNegativeContentQuestionState(file.name);
      reader.onload = function (event) {
        // O resultado é armazenado em event.target.result
        const base64String = event.target.result;

        onSubmitUpdateQuestion(questionId, {
          negative: {
            contentFile: base64String,
            content: file.name,
            type: "negative",
            name: file.name,
            type_file: file.type,
            idType: negativeId,
          },
        });
      };
      reader.readAsDataURL(file);
    }

    // setNegativeContentQuestionState(file.name);
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = async () => {};

    // await app
    //   .storage()
    //   .ref(`/content/${file.name}`)
    //   .put(file)
    //   .then(() => {
    //     app
    //       .storage()
    //       .ref(`/content/${file.name}`)
    //       .getDownloadURL()
    //       .then(async (url) => {
    //         onSubmitUpdateQuestion(questionId, {
    //           negative: { content: url, type: "negative" },
    //         });
    //       });
    //   });
  };

  useEffect(() => {
    setPositiveUuid(`Positive ${uuidv4()}`);
    setPartialUuid(`Partial ${uuidv4()}`);
    setNegativeUuid(`Negative ${uuidv4()}`);
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3 className="textTitleFormJourney">Pergunta {indexQuest + 1}</h3>
        {indexQuest + 1 > 1 && (
          <img
            style={{
              height: "20px",
              transform: "translateY(-3px)",
              cursor: "pointer",
            }}
            onClick={destroy}
            src={iconDelete}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          gap: "20px",
          marginTop: "20px",
        }}
      >
        <Input
          disabled={notJourney}
          onChange={(e) => setNameQuestionState(e.target.value)}
          onBlur={(e) =>
            onChangeQuestionCreated(valueAndType(e, "name"), questionId)
          }
          value={nameQuestionState}
          width="100%"
          maxWidth="351px"
          name="Pergunta"
          placeholder="Escreva a pergunta"
          type={"text"}
        />

        <InputSelectFilterManager
          disabled={notJourney || notQuestion}
          onChange={(e) =>
            onChangeQuestionCreated(valueAndType(e, "macro"), questionId)
          }
          selectOption={macroQuestionState}
          maxWidth="243px"
          color="var(--grey-1)"
          name="Macro"
          width="100%"
          top="50px"
          custom="false"
          type="macro"
        />
        <InputSelectFilterManager
          disabled={notJourney || notQuestion}
          onChange={(e) =>
            onChangeQuestionCreated(valueAndType(e, "column"), questionId)
          }
          selectOption={columnQuestionState}
          maxWidth="243px"
          color="var(--grey-1)"
          name="Pilar"
          width="100%"
          top="50px"
          custom="false"
          type="column"
        />
        <Input
          disabled={notJourney || notQuestion}
          onBlur={(e) =>
            onChangeQuestionCreated(valueAndType(e, "order"), questionId)
          }
          onChange={(e) => setOrderQuestionState(e.target.value)}
          value={orderQuestionState}
          width="100%"
          maxWidth="70px"
          name="Ordem"
          placeholder="Insira"
          type={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Input
          disabled={notJourney || notQuestion}
          value={"Sim"}
          width="100%"
          maxWidth="76px"
          name="Resposta"
          placeholder=""
          type={"text"}
        />
        <Input
          disabled={notJourney || notQuestion}
          onBlur={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "positive", "task"),
              questionId
            )
          }
          onChange={(e) => setPositiveTaskQuestionState(e.target.value)}
          value={positiveTaskQuestionState}
          width="100%"
          maxWidth="351px"
          name="Tarefa"
          placeholder=""
          type={"text"}
        />
        <InputSelectFilterManager
          disabled={notJourney || notQuestion}
          onChange={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "positive", "partner"),
              questionId
            )
          }
          selectOption={positivePartnerQuestionState}
          maxWidth="198px"
          color="var(--grey-1)"
          name="Parceiro recomendado"
          width="100%"
          top="50px"
          custom="false"
          type="business"
        />
        <Input
          label={positiveUuid}
          disabled={true}
          deletIcon={false}
          setValue={setPositiveContentQuestionState}
          value={positiveContentQuestionState}
          deleteContent={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "positive", "content"),
              questionId,
              positiveId
            )
          }
          icon={uploadIconSmall}
          destroyIcon={destroyIconSmall}
          width="100%"
          maxWidth="192px"
          name="Conteúdo"
          placeholder=""
          type={"text"}
        />
        <Input
          disabled={notJourney || notQuestion}
          onBlur={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "positive", "points"),
              questionId
            )
          }
          value={positivePointsQuestionState}
          onChange={(e) => setPositivePointsQuestionState(e.target.value)}
          width="100%"
          maxWidth="70px"
          name="Pontos"
          placeholder=""
          type={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Input
          disabled={notJourney || notQuestion}
          value={"Parcial"}
          width="100%"
          maxWidth="76px"
          name="Resposta"
          placeholder=""
          type={"text"}
        />
        <Input
          disabled={notJourney || notQuestion}
          onChange={(e) => setPartialTaskQuestionState(e.target.value)}
          onBlur={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "partial", "task"),
              questionId
            )
          }
          value={partialTaskQuestionState}
          width="100%"
          maxWidth="351px"
          name="Tarefa"
          placeholder=""
          type={"text"}
        />
        <InputSelectFilterManager
          disabled={notJourney || notQuestion}
          onChange={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "partial", "partner"),
              questionId
            )
          }
          selectOption={partialPartnerQuestionState}
          maxWidth="198px"
          color="var(--grey-1)"
          name="Parceiro recomendado"
          width="100%"
          top="50px"
          custom="false"
          type="business"
        />
        <Input
          label={partialUuid}
          disabled={true}
          deletIcon={false}
          setValue={setPartialContentQuestionState}
          value={partialContentQuestionState}
          deleteContent={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "partial", "content"),
              questionId,
              partialId
            )
          }
          icon={uploadIconSmall}
          destroyIcon={destroyIconSmall}
          width="100%"
          maxWidth="192px"
          name="Conteúdo"
          placeholder=""
          type={"text"}
        />
        <Input
          disabled={notJourney || notQuestion}
          onChange={(e) => setPartialPointsQuestionState(e.target.value)}
          onBlur={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "partial", "points"),
              questionId
            )
          }
          width="100%"
          value={partialPointsQuestionState}
          maxWidth="70px"
          name="Pontos"
          placeholder=""
          type={"text"}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          gap: "20px",
        }}
      >
        <Input
          disabled={notJourney || notQuestion}
          value={"Não"}
          width="100%"
          maxWidth="76px"
          name="Resposta"
          placeholder=""
          type={"text"}
        />
        <Input
          disabled={notJourney || notQuestion}
          onChange={(e) => setNegativeTaskQuestionState(e.target.value)}
          onBlur={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "negative", "task"),
              questionId
            )
          }
          value={negativeTaskQuestionState}
          width="100%"
          maxWidth="351px"
          name="Tarefa"
          placeholder=""
          type={"text"}
        />
        <InputSelectFilterManager
          disabled={notJourney || notQuestion}
          onChange={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "negative", "partner"),
              questionId
            )
          }
          selectOption={negativePartnerQuestionState}
          maxWidth="198px"
          color="var(--grey-1)"
          name="Parceiro recomendado"
          width="100%"
          top="50px"
          custom="false"
          type="business"
        />
        <Input
          label={negativeUuid}
          disabled={true}
          deletIcon={false}
          setValue={setNegativeContentQuestionState}
          value={negativeContentQuestionState}
          deleteContent={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "negative", "content"),
              questionId,
              negativeId
            )
          }
          icon={uploadIconSmall}
          destroyIcon={destroyIconSmall}
          width="100%"
          maxWidth="192px"
          name="Conteúdo"
          placeholder=""
          type={"text"}
        />
        <Input
          disabled={notJourney || notQuestion}
          onChange={(e) => setNegativePointsQuestionState(e.target.value)}
          onBlur={(e) =>
            onChangeQuestionCreated(
              valueAndType(e, "negative", "points"),
              questionId
            )
          }
          value={negativePointsQuestionState}
          width="100%"
          maxWidth="70px"
          name="Pontos"
          placeholder=""
          type={"text"}
        />
      </div>

      <input
        onChange={(e) => loadImagePositive(e.target.files[0])}
        accept="image/pdf"
        name="positive"
        id={positiveUuid}
        type="file"
        style={{ display: "none" }}
      />
      <input
        onChange={(e) => loadImagePartial(e.target.files[0])}
        accept="image/pdf"
        name="partial"
        id={partialUuid}
        type="file"
        style={{ display: "none" }}
      />
      <input
        onChange={(e) => loadImageNegative(e.target.files[0])}
        accept="image/pdf"
        name="negative"
        id={negativeUuid}
        type="file"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default CardJourneyList;
