import axios from "axios";

const api = axios.create({
  baseURL: "https://api.guidz.com.br",
  // baseURL: "http://146.190.186.215",
  // baseURL: "https://api-guidz-dot-absolute-router-392014.de.r.appspot.com",
  // baseURL: "http://localhost:8080",
  timeout: 10000,
});

export default api;
